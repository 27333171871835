import environment from '../config/environment';
import { securityMap } from './securityMap';

export const path = {
  dashboard: {
    href: '/dashboard',
    name: 'Dashboard',
    icon: 'faHome',
    adminCode: [securityMap.admin.dashboard],
    merchantCode: [securityMap.merchant.merchantDashboard],
    publisherCode: [securityMap.publisher.publisherDashboard],
    global: false,
  },
  merchants: {
    href: undefined,
    name: 'Merchants',
    icon: 'faBuildingColumns',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  account: {
    href: undefined,
    name: 'Account',
    icon: 'faGlobe',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  accountDetails: {
    href: '/account-details',
    name: 'Account Details',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantAccountAccountDetails, securityMap.merchant.merchantAccountUserManagement],
    publisherCode: [securityMap.publisher.publisherAccountAccountDetails, securityMap.publisher.publisherAccountUserManagement],
    global: false,
  },
  accountBalance: {
    href: '/account-balance',
    name: 'Account Balance',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantAccountAccountBalance],
    publisherCode: [],
    global: false,
  },
  programDetails: {
    href: '/program-details',
    name: 'Program Details',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantAccountProgramSettings],
    publisherCode: [securityMap.publisher.publisherMerchantsMerchantManagement],
    global: false,
  },
  productDetails: {
    href: '/product-details',
    name: 'Product Details',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantAccountProductCatalogue],
    publisherCode: [securityMap.publisher.publisherMerchantsMerchantManagement, securityMap.publisher.publisherAdsProductFeed],
    global: false,
  },
  productCatalog: {
    href: '/product-catalog',
    name: 'Product Catalog',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantAccountProductCatalogue],
    publisherCode: [],
    global: false,
  },
  editProduct: {
    href: '/edit-product',
    name: 'Edit Product',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantAccountProductCatalogue],
    publisherCode: [],
    global: false,
  },
  trackingSettings: {
    href: '/manage-tracking',
    name: 'Tracking/Transactions Settings',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantAccountTrackingTransactionSettings],
    publisherCode: [],
    global: false,
  },
  trackingDetails: {
    href: '/tracking-details',
    name: 'Tracking Details',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [securityMap.publisher.publisherAccountTrackingDetails],
    global: false,
  },
  postbackDetails: {
    href: '/postback-details',
    name: 'Postback Details',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantPostbackDetails],
    publisherCode: [securityMap.publisher.publisherPostbackDetails],
    global: false,
  },
  overview: {
    href: '/overview',
    name: 'Overview',
    icon: 'faUser',
    adminCode: [],
    merchantCode: [],
    publisherCode: [securityMap.publisher.publisherOverview],
    global: false,
  },
  commissions: {
    href: '/manage-commissions',
    name: 'Commissions',
    icon: 'faDollarSign',
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantCommissions],
    publisherCode: [],
    global: false,
  },
  editCommissions: {
    href: '/edit-commissions',
    name: 'Edit Commissions',
    icon: 'faDollarSign',
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantCommissions],
    publisherCode: [],
    global: false,
  },
  memberships: {
    href: '/memberships',
    name: 'Memberships',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [securityMap.publisher.publisherMerchantsMerchantManagement],
    global: false,
  },
  searchProgram: {
    href: '/search-program',
    name: 'Search Program',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [securityMap.publisher.publisherMerchantsSearchPrograms],
    global: false,
  },
  publishers: {
    href: undefined,
    name: 'Publishers',
    icon: 'faUser',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  ads: {
    href: undefined,
    name: 'Ads',
    icon: 'faCreditCard',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  adManagement: {
    href: '/ad-management',
    name: 'Ad Management',
    icon: undefined,
    adminCode: [securityMap.admin.adsManagement],
    merchantCode: [securityMap.merchant.merchantAdsManagement],
    publisherCode: [],
    global: false,
  },
  editAd: {
    href: '/edit-ad',
    name: 'Edit Ad',
    icon: undefined,
    adminCode: [securityMap.admin.adsManagement],
    merchantCode: [securityMap.merchant.merchantAdsManagement],
    publisherCode: [],
    global: false,
  },
  adSearch: {
    href: '/ad-search',
    name: 'Search',
    icon: undefined,
    adminCode: [securityMap.admin.adsSearch],
    merchantCode: [securityMap.merchant.merchantAdSearch],
    publisherCode: [securityMap.publisher.publisherAdsAdSearch],
    global: false,
  },
  campaignManagement: {
    href: '/campaign-management',
    name: 'Campaign Management',
    icon: undefined,
    adminCode: [securityMap.admin.adsCampaignManagement],
    merchantCode: [securityMap.merchant.merchantAdsCampaignManagement],
    publisherCode: [],
    global: false,
  },
  productFeed: {
    href: '/product-feed',
    name: 'Product Feed',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [securityMap.publisher.publisherAdsProductFeed],
    global: false,
  },
  productFeedDetails: {
    href: '/product-feed-details',
    name: 'Product Feed Details',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [securityMap.publisher.publisherAdsProductFeed],
    global: false,
  },
  reports: {
    href: undefined,
    name: 'Reports',
    icon: 'faChartLine',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  fintelMonitor: {
    href: undefined,
    name: 'Fintel Monitor',
    icon: 'faMagnifyingGlassChart',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  performanceReport: {
    href: '/performance-report',
    name: 'Performance Report',
    icon: undefined,
    adminCode: [securityMap.admin.reportsPerformanceReport],
    merchantCode: [securityMap.merchant.merchantReportsPerformanceReport],
    publisherCode: [securityMap.publisher.publisherReportsPerformanceReport],
    global: false,
  },
  transactionMaster: {
    href: '/transaction-master',
    name: 'Transaction Master',
    icon: undefined,
    adminCode: [securityMap.admin.reportsTransactionMaster],
    merchantCode: [securityMap.merchant.merchantReportsTransactionMaster],
    publisherCode: [securityMap.publisher.publisherReportsTransactionMaster],
    global: false,
  },
  manageSavedReports: {
    href: '/manage-saved-reports',
    name: 'Manage Saved Reports',
    icon: undefined,
    adminCode: [securityMap.admin.reportsManageSavedReports],
    merchantCode: [securityMap.merchant.merchantReportsManageSavedReports],
    publisherCode: [securityMap.publisher.publisherReportsManageSavedReport],
    global: false,
  },
  editSavedReports: {
    href: '/edit-saved-reports',
    name: 'Edit Saved Reports',
    icon: undefined,
    adminCode: [securityMap.admin.reportsEditSavedReports],
    merchantCode: [securityMap.merchant.merchantReportsEditSavedReports],
    publisherCode: [securityMap.publisher.publisherReportsEditSavedReport],
    global: false,
  },
  fintelChecks: {
    href: undefined,
    name: 'Fintel Check',
    icon: 'faCheck',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  fintelChecksReports: {
    href: undefined,
    name: 'Fintel Check Reports',
    icon: 'faCheck',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  fintelChecksSettings: {
    href: undefined,
    name: 'Fintel Check Settings',
    icon: 'faCheck',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  fintelCheckReport: {
    href: '/fintel-check-report',
    name: 'Fintel Check Report',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckFintelCheckReport],
    publisherCode: [],
    global: false,
  },
  monitoringRulesReport: {
    href: '/monitoring-rules-report',
    name: 'Monitoring Rules Report',
    icon: undefined,
    adminCode: [securityMap.admin.fintelMonitoringRulesReport],
    merchantCode: [securityMap.merchant.merchantFintelMonitoringRulesReport],
    publisherCode: [],
    global: false,
  },
  monitoringRulesHistoryReport: {
    href: '/monitoring-rules-history-report',
    name: 'Monitoring Rules History Report',
    icon: undefined,
    adminCode: [securityMap.admin.fintelMonitoringRulesReport],
    merchantCode: [securityMap.merchant.merchantFintelMonitoringRulesReport],
    publisherCode: [],
    global: false,
  },
  fintelCheckHistoryReport: {
    href: '/fintel-check-history-report',
    name: 'Fintel Check Report',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckHistory],
    publisherCode: [],
    global: false,
  },
  fintelCheckHistory: {
    href: '/fintel-check-history',
    name: 'Check History',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckHistory],
    publisherCode: [],
    global: false,
  },
  ruleManager: {
    href: '/rule-manager',
    name: 'Rule Manager',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckRuleManager],
    publisherCode: [],
    global: false,
  },
  linkManager: {
    href: '/link-manager',
    name: 'Link Manager',
    icon: undefined,
    adminCode: [securityMap.admin.fintelCheckLinkManager],
    merchantCode: [securityMap.merchant.merchantFintelCheckLinkManager],
    publisherCode: [securityMap.publisher.publisherFintelMonitorLinkManager],
    global: false,
  },
  newSiteMonitoring: {
    href: '/new-site-monitoring',
    name: 'New Site Monitoring',
    icon: undefined,
    adminCode: [securityMap.admin.fintelCheckNewSiteMonitoring],
    merchantCode: [securityMap.merchant.merchantFintelCheckNewSiteMonitoring],
    publisherCode: [],
    global: false,
  },
  fintelCheckChangeMonitoring: {
    href: '/change-monitoring',
    name: 'Change Monitoring Report',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckChangeMonitoring],
    publisherCode: [],
    global: false,
  },
  fintelCheckChangeMonitoringDetails: {
    href: '/change-monitoring/details',
    name: 'Change Monitoring Report Details',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckChangeMonitoringDetails],
    publisherCode: [],
    global: false,
  },
  adminCheckReport: {
    href: '/fintel-check-report',
    name: 'Fintel Check Report',
    icon: undefined,
    adminCode: [securityMap.admin.fintelCheckAdminCheckReport],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  fintelCheckSettings: {
    href: '/fintel-check-settings',
    name: 'Fintel Check Settings',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckFintelCheckingSettings],
    publisherCode: [],
    global: false,
  },
  fintelCheckCustomPublisherList: {
    href: '/fintel-check-custom-publisher-list',
    name: 'Custom Publisher List',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckPublisherList],
    publisherCode: [],
    global: false,
  },
  fintelCheckManualUrlsList: {
    href: '/fintel-check-manual-urls-list',
    name: 'Manual URLs List',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckFintelCheckingSettings],
    publisherCode: [],
    global: false,
  },
  fintelCheckBlacklist: {
    href: '/fintel-check-blacklist-domains',
    name: 'Blacklist Domains',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckFintelCheckingSettings],
    publisherCode: [],
    global: false,
  },
  fintelCheckDetails: {
    href: '/fintel-check-details',
    name: 'Fintel Check Details',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckFintelCheckReport],
    publisherCode: [],
    global: false,
  },
  fintelCheckRuleDetails: {
    href: '/fintel-check-rule-details',
    name: 'Fintel Check Rule Details',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckFintelCheckReport],
    publisherCode: [],
  },
  fintelCheckChangeHistory: {
    href: '/fintel-check-change-history',
    name: 'Change History',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckHistory],
    publisherCode: [],
    global: false,
  },
  fintelCheckEditRule: {
    href: '/fintel-check-edit-rule',
    name: 'Edit Rule',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckRuleManager],
    publisherCode: [],
  },
  transactions: {
    href: undefined,
    name: 'Transactions',
    icon: 'faArrowRightArrowLeft',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  pendingTransactions: {
    href: '/pending-transactions',
    name: 'Pending Transactions',
    icon: undefined,
    adminCode: [securityMap.admin.transactionsPendingTransactions],
    merchantCode: [securityMap.merchant.merchantTransactionsPendingTransactions],
    publisherCode: [],
    global: false,
  },
  addTransactions: {
    href: '/add-transactions',
    name: 'Add Transactions',
    icon: undefined,
    adminCode: [securityMap.admin.transactionsAddTransactions],
    merchantCode: [securityMap.merchant.merchantTransactionsAddTransactions],
    publisherCode: [],
    global: false,
  },
  payments: {
    href: undefined,
    name: 'Payments',
    icon: 'faCoins',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  generatePayment: {
    href: '/generate-payment',
    name: 'Generate Payments',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsGeneratePayments, securityMap.admin.paymentsEstimatePayments],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  processPayment: {
    href: '/process-payment',
    name: 'Process Payment',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsProcessPayments, securityMap.admin.paymentsPayPayments, securityMap.admin.paymentsApprovePayments],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  paymentReport: {
    href: '/payment-report',
    name: 'Payment Report',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsPaymentReport],
    merchantCode: [securityMap.merchant.merchantReportsPaymentReport],
    publisherCode: [securityMap.publisher.publisherReportsPaymentReport],
    global: false,
  },
  paymentReportDetails: {
    href: '/payment-report-details',
    name: 'Payment Report Details',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsGeneratePayments, securityMap.admin.paymentsPaymentReport],
    merchantCode: [securityMap.merchant.merchantReportsPaymentReport],
    publisherCode: [securityMap.publisher.publisherReportsPaymentReport],
    global: false,
  },
  detailedPaymentRecords: {
    href: '/detailed-payment-record',
    name: 'Detailed Payment Records',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsPaymentReport, securityMap.admin.paymentsGeneratePayments],
    merchantCode: [securityMap.merchant.merchantReportsPaymentReport],
    publisherCode: [securityMap.publisher.publisherReportsPaymentReport],
    global: false,
  },
  currencyRates: {
    href: '/currency-rates',
    name: 'Currency Rates',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsCurrencyRates],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  manualPublisherBonuses: {
    href: '/manual-publisher-bonuses',
    name: 'Manual Publisher Bonuses',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsManualPublisherBonuses],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  merchantAccountBalanceManagement: {
    href: '/account-balance-management',
    name: 'Merchant Account Balance Management',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsAccountBalance],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  merchantAccountBalanceStats: {
    href: '/account-balance-management/merchant',
    name: 'Account Balance Management',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsAccountBalance],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  merchantAccountBalanceManagementDetails: {
    href: '/account-balance-management/details',
    name: 'Account Balance Details',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsAccountBalance],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  merchantBalanceManagementDetails: { // Merchant display route
    href: '/account-balance/details',
    name: 'Account Balance Details',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantAccountAccountBalance],
    publisherCode: [],
    global: false,
  },
  merchantInvoiceStatement: { // Merchant display routes
    href: '/account-balance/statement',
    name: 'Invoice Statement',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantAccountAccountBalance],
    publisherCode: [],
    global: false,
  },
  merchantAccountBalanceManagementNewStats: {
    href: '/account-balance-management/new-stats',
    name: 'Update Account Balance Stats',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsAccountBalance],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  notifications: {
    href: '/notifications',
    name: 'Notifications',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  invoiceStatement: {
    href: '/account-balance-management/statement',
    name: 'Invoice Statement',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsAccountBalance],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  settings: {
    href: undefined,
    name: 'Settings',
    icon: 'faCog',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  userManagement: {
    href: '/user-management',
    name: 'User Management',
    icon: undefined,
    adminCode: [securityMap.admin.settingsUserManagement],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  publisherInvites: {
    href: '/publisher-invites',
    name: 'Publisher Invites',
    icon: undefined,
    adminCode: [securityMap.admin.settingsPublisherInvites],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  securityPermissions: {
    href: '/security-permissions',
    name: 'Security Permissions',
    icon: undefined,
    adminCode: [securityMap.admin.settingsSecurityPermissions],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  messageHub: {
    href: environment.app.supportUrl,
    name: 'Messaging Hub',
    icon: 'faComments',
    link: 'faArrowUpRightFromSquare',
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  membershipManagement: {
    href: '/membership-management',
    name: 'Membership Management',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantPublishersMembershipManagement],
    publisherCode: [],
    global: false,
  },
  publisherInvitations: {
    href: '/publisher-invitations',
    name: 'Publisher Invitations',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantPublishersPublisherInvitations],
    publisherCode: [],
    global: false,
  },
  sendPublisherInvitation: {
    href: '/send-publisher-invitation',
    name: 'Send Publisher Invitation',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantPublishersPublisherInvitations],
    publisherCode: [],
    global: false,
  },
  publisherSearch: {
    href: '/publisher-search',
    name: 'Search',
    icon: undefined,
    adminCode: [securityMap.admin.publishers],
    merchantCode: [securityMap.merchant.merchantPublishersPublisherSearch],
    publisherCode: [],
    global: false,
  },
  publisherSearchDetail: {
    href: '/publisher-search/detail',
    name: 'Search',
    icon: undefined,
    adminCode: [securityMap.admin.publishers, securityMap.publisher.publisherAccountAccountDetails, securityMap.publisher.publisherAccountUserManagement],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  publisherGroups: {
    href: '/publisher-groups',
    name: 'Publisher Groups',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantPublishersPublisherGroup],
    publisherCode: [],
    global: false,
  },
  internalPublisher: {
    href: '/internal-publisher',
    name: 'Internal Publisher Details',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantPublishersInternalPublisher],
    publisherCode: [],
    global: false,
  },
  manageCommissions: {
    href: '/manage-commissions',
    name: 'Manage Commissions',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantCommissions],
    publisherCode: [],
    global: false,
  },
  callback: {
    href: '/callback',
    name: 'Callback',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  login: {
    href: '/login',
    name: 'Login',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  createAccountStep1: {
    href: '/create-account-step-1',
    name: 'User Info',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  createAccountStep2: {
    href: '/create-account-step-2',
    name: 'Contact Info',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  createAccountStep3: {
    href: '/create-account-step-3',
    name: 'Publisher',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  createAccountStep4: {
    href: '/create-account-step-4',
    name: 'Payment Info',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  createAccountStep5: {
    href: '/create-account-step-5',
    name: 'Merchant Program',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  createAccountStep6: {
    href: '/create-account-step-6',
    name: 'Confirmation',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  userProfileManagement: {
    href: '/user-profile-management',
    name: 'User Profile Management',
    icon: undefined,
    adminCode: [securityMap.admin.userProfileManagement],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  userAccountManagement: {
    href: '/user-account-management',
    name: 'User Account Management',
    icon: undefined,
    adminCode: [securityMap.admin.settingsUserManagement],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  activeComissions: {
    href: '/active-commissions',
    name: 'Commissions',
    icon: 'faDollarSign',
    adminCode: [],
    merchantCode: [],
    publisherCode: [securityMap.publisher.publisherCommissions],
    global: false,
  },
  merchantManagement: {
    href: '/merchant-management',
    name: 'Merchant Management',
    icon: undefined,
    adminCode: [securityMap.admin.merchants],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  merchantManagementDetail: {
    href: '/merchant-management/detail',
    name: 'Merchant Account Settings',
    icon: undefined,
    adminCode: [securityMap.admin.merchants, securityMap.merchant.merchantAccountAccountDetails, securityMap.merchant.merchantAccountUserManagement],
    merchantCode: [],
    publisherCode: [],
    global: false,
  },
  accountBalanceManagement: {
    href: '/account-balance-management',
    name: 'Account Balance Management',
    icon: undefined,
    adminCode: [securityMap.admin.paymentsAccountBalance],
    merchantCode: [securityMap.merchant.merchantAccountAccountBalance],
    publisherCode: [],
    global: false,
  },
  invitationTemplate: {
    href: '/publisher-invitation-template',
    name: 'Invitation Template',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantPublishersPublisherInvitations],
    publisherCode: [],
    global: false,
  },
  invitationHistory: {
    href: '/invitation-history',
    name: 'Invitations History',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantPublishersPublisherInvitations],
    publisherCode: [],
    global: false,
  },
  addPublisherGroup: {
    href: '/add-publisher-group',
    name: 'Add Publisher Group',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantPublishersPublisherGroup],
    publisherCode: [],
    global: false,
  },
  editPublisherGroup: {
    href: '/edit-publisher-group',
    name: 'Edit Publisher Group',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantPublishersPublisherGroup],
    publisherCode: [],
    global: false,
  },
  programSettings: {
    href: '/program-settings',
    name: 'Program Settings',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantAccountProgramSettings],
    publisherCode: [],
    global: false,
  },
  membershipDetails: {
    href: '/membership-detail',
    name: 'Publisher Details',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantPublishersMembershipManagement, securityMap.merchant.merchantPublishersPublisherInvitations],
    publisherCode: [],
    global: false,
  },
  trackingTransactionsSettings: {
    href: '/tracking-transactions-settings',
    name: 'Tracking Transactions Settings',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantAccountTrackingTransactionSettings],
    publisherCode: [],
    global: false,
  },
  fintelCheckPublisherSearch: {
    href: '/fintel-check-custom-publisher-list',
    name: 'Select the publishers to be checked',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantFintelCheckFintelCheckingSettings],
    publisherCode: [],
    global: false,
  },
  editCampaign: {
    href: '/edit-campaign',
    name: 'Edit Campaign',
    icon: undefined,
    adminCode: [securityMap.admin.adsCampaignManagement],
    merchantCode: [securityMap.merchant.merchantAdsCampaignManagement],
    publisherCode: [],
    global: false,
  },
  createCampaign: {
    href: '/create-campaign',
    name: 'Create Campaign',
    icon: undefined,
    adminCode: [securityMap.admin.adsCampaignManagement],
    merchantCode: [securityMap.merchant.merchantAdsCampaignManagement],
    publisherCode: [],
    global: false,
  },
  forgotPassword: {
    href: '/forgot-password',
    name: 'Forgot Password',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  setPassword: {
    href: '/set-pw',
    name: 'Fintel Connect Network Consent',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  invalidUser: {
    href: '/invalid-user',
    name: 'Invalid User',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  unverified: {
    href: '/unverified',
    name: 'Unverified Email',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  addNewAdLink: {
    href: '/add-new-ad-link',
    name: 'Add A Text Link',
    icon: undefined,
    adminCode: [securityMap.admin.adsManagement, securityMap.admin.adsSearch],
    merchantCode: [securityMap.merchant.merchantAdsManagement, securityMap.merchant.merchantAdSearch],
    publisherCode: [],
    global: false,
  },
  addNewAdHtml: {
    href: '/add-new-ad-html',
    name: 'Add A Html5 Ad',
    icon: undefined,
    adminCode: [securityMap.admin.adsManagement, securityMap.admin.adsSearch],
    merchantCode: [securityMap.merchant.merchantAdsManagement, securityMap.merchant.merchantAdSearch],
    publisherCode: [],
    global: false,
  },
  addNewAdBanner: {
    href: '/add-new-ad-banner',
    name: 'Add A Banner',
    icon: undefined,
    adminCode: [securityMap.admin.adsManagement, securityMap.admin.adsSearch],
    merchantCode: [securityMap.merchant.merchantAdsManagement, securityMap.merchant.merchantAdSearch],
    publisherCode: [],
    global: false,
  },
  addNewUser: {
    href: '/add-new-user',
    name: 'Add New User',
    icon: undefined,
    adminCode: [securityMap.admin.settingsUserManagement],
    merchantCode: [],
    publisherCode: [],
  },
  addNewMerchant: {
    href: '/add-merchant',
    name: 'Add Merchant',
    icon: undefined,
    adminCode: [securityMap.admin.merchants],
    merchantCode: [],
    publisherCode: [],
  },
  addNewProduct: {
    href: '/add-new-product',
    name: 'Add Product',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantAccountProductCatalogue],
    publisherCode: [],
  },
  addNewCommission: {
    href: '/add-new-commission',
    name: 'Add Commission',
    icon: undefined,
    adminCode: [],
    merchantCode: [securityMap.merchant.merchantCommissions],
    publisherCode: [],
  },
  emailVerified: {
    href: '/email-verified',
    name: 'Email Verified',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
  maintenance: {
    href: '/maintenance',
    name: 'maintenance',
    icon: undefined,
    adminCode: [],
    merchantCode: [],
    publisherCode: [],
    global: true,
  },
};
