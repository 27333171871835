import { MERCHANT_PREFIX, path, securityMap } from '../../../utils';

export const merchant = [
  {
    key: securityMap.merchant.merchantDashboard,
    merchantSelf: true,
    merchantManaged: false,
    merchantResults: true,
    icon: path.dashboard.icon,
    href: MERCHANT_PREFIX + path.dashboard.href,
    label: path.dashboard.name,
  },
  {
    key: securityMap.merchant.merchantAccount,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.account.name,
    icon: path.account.icon,
    subSections: [
      {
        key: [securityMap.merchant.merchantAccountAccountDetails, securityMap.merchant.merchantAccountUserManagement],
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.accountDetails.href,
        label: path.accountDetails.name,
      },
      {
        key: securityMap.merchant.merchantAccountAccountBalance,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.accountBalance.href,
        label: path.accountBalance.name,
      },
      {
        key: securityMap.merchant.merchantAccountProgramSettings,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.programSettings.href,
        label: path.programSettings.name,
      },
      {
        key: securityMap.merchant.merchantAccountProductCatalogue,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.productCatalog.href,
        label: path.productCatalog.name,
      },
      {
        key: securityMap.merchant.merchantAccountTrackingTransactionSettings,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.trackingSettings.href,
        label: path.trackingSettings.name,
      },
      {
        key: securityMap.merchant.merchantPostbackDetails,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.postbackDetails.href,
        label: path.postbackDetails.name,
      },
    ],
  },
  {
    key: securityMap.merchant.merchantCommissions,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    icon: path.commissions.icon,
    href: MERCHANT_PREFIX + path.commissions.href,
    label: path.commissions.name,
  },
  {
    key: securityMap.merchant.merchantPublishers,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.publishers.name,
    icon: path.publishers.icon,
    subSections: [
      {
        key: securityMap.merchant.merchantPublishersMembershipManagement,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.membershipManagement.href,
        label: path.membershipManagement.name,
      },
      {
        key: securityMap.merchant.merchantPublishersPublisherInvitations,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.publisherInvitations.href,
        label: path.publisherInvitations.name,
      },
      {
        key: securityMap.merchant.merchantPublishersPublisherSearch,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.publisherSearch.href,
        label: path.publisherSearch.name,
      },
      {
        key: securityMap.merchant.merchantPublishersPublisherGroup,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.publisherGroups.href,
        label: path.publisherGroups.name,
      },
      {
        key: securityMap.merchant.merchantPublishersInternalPublisher,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.internalPublisher.href,
        label: path.internalPublisher.name,
      },
    ],
  },
  {
    key: securityMap.merchant.merchantAds,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.ads.name,
    icon: path.ads.icon,
    subSections: [
      {
        key: securityMap.merchant.merchantAdSearch,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.adSearch.href,
        label: path.adSearch.name,
      },
      {
        key: securityMap.merchant.merchantAdsCampaignManagement,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.campaignManagement.href,
        label: path.campaignManagement.name,
      },
    ],
  },
  {
    key: securityMap.merchant.merchantReports,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.reports.name,
    icon: path.reports.icon,
    subSections: [
      {
        key: securityMap.merchant.merchantReportsPerformanceReport,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.performanceReport.href,
        label: path.performanceReport.name,
      },
      {
        key: securityMap.merchant.merchantReportsTransactionMaster,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.transactionMaster.href,
        label: path.transactionMaster.name,
      },
      {
        key: securityMap.merchant.merchantReportsPaymentReport,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.paymentReport.href,
        label: path.paymentReport.name,
      },
      {
        key: securityMap.merchant.merchantReportsManageSavedReports,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.manageSavedReports.href,
        label: path.manageSavedReports.name,
      },
    ],
  },
  {
    key: securityMap.merchant.merchantFintelCheck,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.fintelChecksReports.name,
    icon: path.fintelChecks.icon,
    subSections: [
      {
        key: securityMap.merchant.merchantFintelCheckFintelCheckReport,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.fintelCheckReport.href,
        label: path.fintelCheckReport.name,
      },
      {
        key: securityMap.merchant.merchantFintelMonitoringRulesReport,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.monitoringRulesReport.href,
        label: path.monitoringRulesReport.name,
      },
      {
        key: securityMap.merchant.merchantFintelCheckChangeMonitoring,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.fintelCheckChangeMonitoring.href,
        label: path.fintelCheckChangeMonitoring.name,
      },
      {
        key: securityMap.merchant.merchantFintelCheckLinkManager,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.linkManager.href,
        label: path.linkManager.name,
      },
      {
        key: securityMap.merchant.merchantFintelCheckNewSiteMonitoring,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.newSiteMonitoring.href,
        label: path.newSiteMonitoring.name,
      },
    ],
  },
  {
    key: securityMap.merchant.merchantFintelCheck,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.fintelChecksSettings.name,
    icon: path.fintelChecks.icon,
    subSections: [
      {
        key: securityMap.merchant.merchantFintelCheckRuleManager,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.ruleManager.href,
        label: path.ruleManager.name,
      },
      {
        key: securityMap.merchant.merchantFintelCheckFintelCheckingSettings,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.fintelCheckSettings.href,
        label: path.fintelCheckSettings.name,
      },
      {
        key: securityMap.merchant.merchantFintelCheckPublisherList,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.fintelCheckCustomPublisherList.href,
        label: path.fintelCheckCustomPublisherList.name,
      },
    ],
  },
  {
    key: securityMap.merchant.merchantTransactions,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.transactions.name,
    icon: path.transactions.icon,
    subSections: [
      {
        key: securityMap.merchant.merchantTransactionsPendingTransactions,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.pendingTransactions.href,
        label: path.pendingTransactions.name,
      },
      {
        key: securityMap.merchant.merchantTransactionsAddTransactions,
        merchantSelf: true,
        merchantManaged: true,
        merchantResults: true,
        href: MERCHANT_PREFIX + path.addTransactions.href,
        label: path.addTransactions.name,
      },
    ],
  },
  {
    key: securityMap.merchant.merchantMessageHub,
    merchantSelf: true,
    merchantManaged: true,
    merchantResults: true,
    label: path.messageHub.name,
    icon: path.messageHub.icon,
    href: path.messageHub.href,
    link: path.messageHub.link,
  },
];
