import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChangeMonitoringInput, ChangeMonitoringOutput, ChangeMonitoringType, GET_MONITORING_DATA, SET_REVIEW_STATUS,
} from '../graphql/queries';
import { useUserInfo } from '../../../../../hooks';
import { MERCHANT_PREFIX, csvGenerator, path } from '../../../../../utils';
import { processDataToCSVString } from '../../FintelCheckDetails/utils';
import { columns as CSVColumns } from '../contracts/columns';

export const useChangeMonitoring = () => {
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();

  // Filters
  const [selectedRunDate, setSelectedRunDate] = useState<Date | undefined>();

  const [isDateCalendarOpen, setIsDateCalendarOpen] = useState(false);

  const [urlSearch, onUrlSearchChange] = useState<string | undefined>(undefined);

  const [filterReviewStatus, setFilterReviewStatus] = useState<{ label: string, value: string }>({ label: 'All Statuses', value: 'All Statuses' });

  // Page
  const [errorMessage, setErrorMessage] = useState('');
  const [refetchPage, setRefetchPage] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [records, setRecords] = useState({ label: '10', value: '10' });
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'runDate', direction: 'desc' });

  // States
  const [selectedItem, setSelectedItem] = useState<string>();

  const [selectedCheckboxItem, setSelectedCheckboxItem] = useState<{ checked: boolean, selected?: ChangeMonitoringType, isCheckAll: boolean }>();
  const [headerCheckbox, setHeaderCheckbox] = useState(false);

  const [selectedReviewStatus, setSelectedReviewStatus] = useState<{ label: string, value: string }>();

  const [changeMonitoringData, setChangeMonitoringData] = useState<ChangeMonitoringType[]>([]);

  const [reviewStatusNote, setReviewStatusNote] = useState<string>('');

  // Queries/Mutations
  const [getMonitoringData, { loading: getMonitoringDataLoading }] = useLazyQuery<ChangeMonitoringOutput, ChangeMonitoringInput>(GET_MONITORING_DATA);
  const [setReviewStatusMutation, { loading: setReviewStatusLoading }] = useMutation(SET_REVIEW_STATUS);

  const fetchPageData = async () => {
    const { data, error } = await getMonitoringData({
      variables: {
        input: {
          merchantId: hookWhoAmI.companyId ?? '0',
          // filters
          urlSearch,
          runDate: selectedRunDate?.toISOString(),
          status: filterReviewStatus?.value === 'All Statuses' ? undefined : filterReviewStatus?.value,

          limit: Number(records.value),
          currentPage,
          sortBy: sortColumn.column,
          sortDirection: sortColumn.direction ?? 'desc',
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (error) {
      setErrorMessage(error.message);
      return;
    }
    if (data && data.changeMonitoringData) {
      const {
        monitoringChanges, count, totalPages: newTotalPages, currentPage: newCurrentPage,
      } = data.changeMonitoringData;
      setChangeMonitoringData(monitoringChanges.map((item) => ({ ...item, checked: false })));
      setTotalPages(newTotalPages);
      setTotalRecords(count);
      window.scrollTo(0, 0);
    }
  };

  if (refetchPage) {
    fetchPageData();
    setRefetchPage(false);
  }

  useEffect(() => {
    if (!refetchPage) {
      setCurrentPage(1);
      setRefetchPage(true);
    }
  }, [selectedRunDate, records, filterReviewStatus]);

  const handleOnChangeCurrentPage = (chosenPage: number) => {
    setCurrentPage(chosenPage);
    setRefetchPage(true);
  };

  const handleOnClearFormData = () => {
    setSelectedRunDate(undefined);
    onUrlSearchChange('');
    setErrorMessage('');
    setCurrentPage(1);
    setRefetchPage(true);
    setFilterReviewStatus({ label: 'All Statuses', value: 'All Statuses' });
  };

  const handleOnSelectDate = (date: Date) => {
    setSelectedRunDate(date);
    setIsDateCalendarOpen(false);
  };

  const onSortHandler = (dataField: string, direction: any) => {
    if (dataField === 'checked') return;
    if (sortColumn.direction === null) {
      setSortColumn({ column: dataField, direction });
    } else {
      setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    }
    setRefetchPage(true);
  };

  const searchButtonHandler = () => {
    fetchPageData();
  };

  const generateCSVHandler = () => {
    const csvString = processDataToCSVString(changeMonitoringData, CSVColumns());
    csvGenerator(csvString, 'Fintel Check Change History');
  };

  useEffect(() => {
    if (selectedItem) {
      const selectedItemData = changeMonitoringData.find((item) => item.pageDifferences === selectedItem);
      navigate(
        `${MERCHANT_PREFIX}${path.fintelCheckChangeMonitoringDetails.href}`,
        { state: { data: selectedItemData } },
      );
    }
  }, [selectedItem, changeMonitoringData]);

  const handleOnViewChangesClick = (url: string) => {
    setSelectedItem(url);
  };

  /**
   * Update selected checkbox item
   * if isCheckAll is true, update all items in the array
   * otherwise, update only the selected item
   */
  const handleUpdateSelectedCheckboxItem = () => {
    if (!selectedCheckboxItem?.isCheckAll) {
      const updatedData = changeMonitoringData.map((item) => (
        item.id === selectedCheckboxItem?.selected?.id
          ? { ...item, checked: selectedCheckboxItem?.checked } : item));
      setChangeMonitoringData(updatedData);
    } else {
      setChangeMonitoringData(
        changeMonitoringData.map((item) => (
          { ...item, checked: selectedCheckboxItem?.checked || false })),
      );
    }
  };

  /**
   * Listen to changes in selectedCheckboxItem state
   */
  useEffect(() => {
    if (selectedCheckboxItem) handleUpdateSelectedCheckboxItem();
  }, [selectedCheckboxItem]);

  /**
   * Handle checkbox selection and update the selected checkbox item state
   * @param checked checkbox value
   * @param selected selected item
   * @param isCheckAll flag to check if all items are selected
   */
  const handleOnSelectCheckbox = (checked: boolean, selected?: ChangeMonitoringType, isCheckAll = false) => {
    if (isCheckAll) setHeaderCheckbox(checked);
    setSelectedCheckboxItem({ checked, selected, isCheckAll });
  };

  // Set review status for the selected change monitoring records and refetch the page
  const handleOnSetReviewStatus = async (status: string, changeId?: string) => {
    const { data, errors } = await setReviewStatusMutation({
      variables: {
        input: {
          status,
          ids: changeId ? [changeId] : changeMonitoringData.filter((item) => item.checked).map((item) => item.id),
          note: reviewStatusNote,
          userId: hookWhoAmI.id ?? '0',
        },
      },
    });
    setSelectedReviewStatus(undefined);
    if (errors || !data?.changeMonitoringSetReviewStatus) {
      setErrorMessage(errors ? errors[0].message : 'An error occurred');
      return;
    }
    if (data && data.changeMonitoringSetReviewStatus) {
      setSelectedCheckboxItem(undefined);
      setHeaderCheckbox(false);
      setTimeout(() => {
        setRefetchPage(true);
      }, 1000);
    }
  };

  // Disable the set review status button if no status is selected or no note is provided
  const shouldDisableSetReviewStatusButton = (latestStatus?: string) => {
    if (reviewStatusNote.length > 0) {
      return false;
    }
    if (!selectedReviewStatus || setReviewStatusLoading) {
      return true;
    }
    if (selectedReviewStatus.label === latestStatus) {
      return true;
    }

    const selectedItems = changeMonitoringData.filter((item) => item.checked);
    if (selectedItems.map((item) => item.status).includes(selectedReviewStatus?.value)) {
      return true;
    }
    return false;
  };

  const resetReviewStatus = () => {
    setSelectedReviewStatus(undefined);
    setReviewStatusNote('');
  };

  useEffect(() => {
    if (changeMonitoringData.length > 0
      && changeMonitoringData.filter((item) => item.checked).length === 0) {
      resetReviewStatus();
    }
  }, [changeMonitoringData]);

  return {
    selectedRunDate,
    handleOnSelectDate,
    isDateCalendarOpen,
    setIsDateCalendarOpen,

    urlSearch,
    onUrlSearchChange,
    searchButtonHandler,

    filterReviewStatus,
    setFilterReviewStatus,

    errorMessage,
    setErrorMessage,

    isLoading: getMonitoringDataLoading,

    currentPage,
    handleOnChangeCurrentPage,

    totalPages,
    setTotalPages,

    records,
    setRecords,
    totalRecords,
    setTotalRecords,

    changeMonitoringData,

    handleOnClearFormData,

    sortColumn,
    onSortHandler,

    generateCSVHandler,

    handleOnViewChangesClick,

    handleOnSelectCheckbox,
    selectedCheckboxItem,
    headerCheckbox,
    setHeaderCheckbox,

    handleOnSetReviewStatus,
    setSelectedReviewStatus,
    selectedReviewStatus,
    reviewStatusNote,
    setReviewStatusNote,
    shouldDisableSetReviewStatusButton,

    setReviewStatusLoading,
  };
};
