import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { DefaultPropsType } from '../../../../../../types';
import {
  AppWrapper, Button, ErrorBanner, PageTitle,
} from '../../../../../../components';
import { Rule, UseFintelRuleManagerType } from '../../types';
import { MERCHANT_PREFIX, dateFormatter, path } from '../../../../../../utils';
import { useFintelRuleManager } from '../../hooks';
import * as Styled from '../../styles';
import { RULE_MANAGER, RULE_TYPES, STATUS_TYPES } from '../../enums';
import { EDIT_RULE_GET_RULE_GROUPS } from '../../graphql/queries/getMerchantRuleGroups';
import DeleteRuleModal from '../../modal/DeleteRuleModal';
import { useUserInfo } from '../../../../../../hooks';
import { toSentenceCase } from '../../utils';
import { formatFeedItem, useDetailsModal } from '../../components/RuleDetailsModal/hooks';
import { ConfirmationModal } from '../../../../../../components/Modal/ConfirmationModal';
import { ProductFeedModal } from '../../components';
import { ALL_PRODUCTS } from '../../components/AddRuleModal/hooks';
import RuleStatusSettingsComponent from '../../components/RuleStatusSettingsComponent';
import { RuleStatusSettingsType } from '../../components/RuleStatusSettingsComponent/enums';
import { FIELD_LABELS, NEW_RULE_SUMMARY, RULE_SUMMARY } from '../../components/MonitoringRuleSummaryModal/enums';

type FintelCheckRuleDetailsProps = DefaultPropsType;

const FintelCheckEditRule = ({ permissionsCodeList = [] }: FintelCheckRuleDetailsProps) => {
  /* hooks and constants */
  const { isReadOnlyList, ...hook }: UseFintelRuleManagerType = useFintelRuleManager(permissionsCodeList);
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();
  const location = useLocation();
  const localStateData: Rule | undefined = location.state.data;
  const defaultOption = { label: '', value: '' };

  /* local states */
  const [editedRule, setEditedRule] = useState<Rule | null>(null);
  const [initialRuleState, setInitialRuleState] = useState<Rule | null>(null);
  const [ruleGroupOptions, setRuleGroupOptions] = useState<SelectOption[]>([]);
  const [statusOptions] = useState<SelectOption[]>([
    { label: STATUS_TYPES.ACTIVE, value: STATUS_TYPES.ACTIVE },
    { label: STATUS_TYPES.INACTIVE, value: STATUS_TYPES.INACTIVE },
  ]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  /* queries */
  const [getGroupNames, { loading: groupNamesLoading, error: groupNamesDropdownError }] = useLazyQuery(EDIT_RULE_GET_RULE_GROUPS);

  /* Product Feed Hook */
  const productFeedHook = useDetailsModal(editedRule ?? undefined);
  const ENUM: Partial<typeof NEW_RULE_SUMMARY> = RULE_SUMMARY;

  /* Get rule group options */
  const handleGetRuleGroupOptions = async () => {
    const { data } = await getGroupNames({ variables: { id: localStateData?.merchantId } });
    if (data && data.getRuleGroupsByMerchant) {
      const options = data.getRuleGroupsByMerchant.groupNames.map((name: string) => ({ label: name, value: name }));
      setRuleGroupOptions(options);
    }
  };

  /* Use location state data to set local states */
  const handleSetLocalStateData = () => {
    setEditedRule(localStateData || null);
    setInitialRuleState(localStateData || null);
    if (localStateData?.startDate) setStartDate(new Date(localStateData?.startDate));
  };

  const handleRuleStatusSettingsChange = (value: RuleStatusSettingsType) => {
    setEditedRule((prevState) => (prevState && { ...prevState, ruleStatusSettings: value }));
    hook.setRuleStatusSettings(value);
  };

  useEffect(() => {
    if (!localStateData) {
      navigate(`${MERCHANT_PREFIX}${path.ruleManager.href}`);
    } else {
      handleSetLocalStateData();
      handleGetRuleGroupOptions();
    }
  }, [localStateData]);

  if (!localStateData) {
    return null;
  }
  return (
    <AppWrapper
      permissionsCodeList={permissionsCodeList}
    >
      <Styled.WrapperStyled>

        <ErrorBanner
          isOpen={!!hook.errorMessage}
          message={hook.errorMessage}
        />

        <Styled.HeaderStyled>
          <PageTitle
            isLoading={hook.dropdownsLoading || hook.ruleManagerLoading}
            loadingText={hook.loadingMessage}
          >
            {path.fintelCheckEditRule.name}
          </PageTitle>

          <Styled.HeaderButtonsStyled>
            <Button
              theme="secondary"
              onClick={() => navigate(`${MERCHANT_PREFIX}${path.ruleManager.href}`)}
            >
              {RULE_MANAGER.EDIT_RULES.CANCEL_BUTTON}
            </Button>

            <Button
              theme="tertiary"
              onClick={() => hook.handleDelete(localStateData)}
              disabled={!localStateData}
            >
              {RULE_MANAGER.EDIT_RULES.DELETE_BUTTON}
            </Button>

            <Button
              theme="primary"
              onClick={() => editedRule && setIsConfirmationModalOpen(true)}
              disabled={
                !localStateData
                || !editedRule
                || editedRule?.ruleName.length <= 0
                || editedRule?.ruleName === ''
                || (editedRule.ruleType === RULE_TYPES.MONITORING && (!editedRule?.ruleStatusSettings
                || Object.values(editedRule?.ruleStatusSettings).includes(null)))
              }
            >
              {RULE_MANAGER.EDIT_RULES.SAVE_BUTTON}
            </Button>
          </Styled.HeaderButtonsStyled>
        </Styled.HeaderStyled>

        <Styled.RuleDetailsWrapper>
          <Styled.EditInputStyled
            label={RULE_MANAGER.EDIT_RULES.RULE_NAME}
            value={editedRule?.ruleName || ''}
            type="text"
            required
            onChange={(e) => setEditedRule((prevState) => (prevState && { ...prevState, ruleName: e.target.value }))}
            error={editedRule && editedRule?.ruleName.length <= 0 ? RULE_MANAGER.EDIT_RULES.RULE_MISSING_NAME_ERROR : undefined}
          />
          <Styled.EditInputStyled
            label={RULE_MANAGER.EDIT_RULES.CREATED_ON}
            value={startDate ? dateFormatter(startDate) : undefined}
            theme="secondary"
            type="text"
            readonly
            disabled
          />
          <Styled.EditSelectStyled
            label={RULE_MANAGER.EDIT_RULES.RULE_GROUP}
            options={ruleGroupOptions}
            selected={editedRule?.ruleGroup ? { label: editedRule.ruleGroup, value: editedRule.ruleGroup } : defaultOption}
            onChange={(option) => setEditedRule((prevState) => (prevState && { ...prevState, ruleGroup: option.value }))}
            isDisabled={groupNamesLoading || !!groupNamesDropdownError}
            error={groupNamesDropdownError?.message}
          />
          <Styled.EditSelectStyled
            label={RULE_MANAGER.EDIT_RULES.RULE_STATUS}
            options={statusOptions}
            selected={editedRule?.status ? { label: editedRule.status, value: editedRule.status } : defaultOption}
            onChange={(option) => setEditedRule((prevState) => (prevState && { ...prevState, status: option.value }))}
          />
        </Styled.RuleDetailsWrapper>

        {editedRule?.ruleType === RULE_TYPES.MONITORING ? (
          <Styled.RuleStatusWrapper>
            <Styled.RuleStatusLabel>
              {RULE_MANAGER.EDIT_RULES.RULE_STATUS}
              <Styled.RequiredStyled>*</Styled.RequiredStyled>
            </Styled.RuleStatusLabel>
            <Styled.RuleStatusDescription>
              {RULE_MANAGER.EDIT_RULES.RULE_STATUS_SETTINGS_DESCRIPTION}
            </Styled.RuleStatusDescription>
            <RuleStatusSettingsComponent
              ruleStatusSettings={
                editedRule?.ruleStatusSettings
                || {
                  fieldsRequired: null,
                  fieldState: null,
                  ruleState: null,
                }
              }
              setRuleStatusSettings={handleRuleStatusSettingsChange}
              errors={{}}
            />
          </Styled.RuleStatusWrapper>
        ) : (
          <Styled.EditRuleWrapper>
            <Styled.EditRuleLabelStyled>
              {RULE_MANAGER.EDIT_RULES.MINIMUM_CONDITIONS}
            </Styled.EditRuleLabelStyled>

            <Styled.EditRuleContentWrapper>
              <Styled.EditInputStyled
                label={RULE_MANAGER.EDIT_RULES.MERCHANT_NAME}
                disclaimer={RULE_MANAGER.EDIT_RULES.MERCHANT_NAME_DISCLAIMER}
                value={hookWhoAmI.companyName}
                theme="secondary"
                type="text"
                readonly
                disabled
              />

              {editedRule && [RULE_TYPES.EVAL_PRODUCT, RULE_TYPES.EVAL_GROUP, RULE_TYPES.MONITORING].includes(editedRule.ruleType) && (
                <Styled.EditInputStyled
                  label={RULE_MANAGER.EDIT_RULES.NOMINATED_FIELD}
                  disclaimer={RULE_MANAGER.EDIT_RULES.NOMINATED_FIELD_DISCLAIMER}
                  value={editedRule?.nominatedField}
                  theme="secondary"
                  type="text"
                  readonly
                  disabled
                />
              )}

              {editedRule && editedRule.customText && [RULE_TYPES.EVAL_TEXT].includes(editedRule.ruleType) && (
                <Styled.EditInputAreaStyled
                  label={RULE_MANAGER.EDIT_RULES.TEXT_MATCH}
                  disclaimer={RULE_MANAGER.EDIT_RULES.TEXT_MATCH_DISCLAIMER}
                  value={editedRule.customText.map((ct) => ct.customText).join(', ')}
                  theme="secondary"
                  readonly
                  disabled
                />
              )}

            </Styled.EditRuleContentWrapper>
          </Styled.EditRuleWrapper>
        )}

        <Styled.EditRuleWrapper>
          <Styled.EditRuleLabelStyled>
            {RULE_MANAGER.EDIT_RULES.RULE_CRITERIA}
          </Styled.EditRuleLabelStyled>

          {editedRule?.ruleType === RULE_TYPES.MONITORING ? (
            <Styled.CustomTextWrapper>
              <Styled.EditRuleLabelStyled>
                {FIELD_LABELS.CUSTOM_TEXT_LIST}
              </Styled.EditRuleLabelStyled>
              <Styled.CustomTextValuesWrapper>
                {editedRule.fieldChecks.map((item: {
                  expectedValue: string
                  fieldName: string
                }, index: number) => (
                  <Styled.CustomTextValue key={index}>
                    <Styled.CTSpanIndex>
                      {index + 1}
                    </Styled.CTSpanIndex>
                    <span>
                      {item.expectedValue}
                    </span>
                  </Styled.CustomTextValue>
                ))}
              </Styled.CustomTextValuesWrapper>
            </Styled.CustomTextWrapper>
          ) : (
            <Styled.RuleCriteriaContentWrapper>
              {editedRule?.productFeed.map((pf, index) => (
                <Styled.RuleCriteriaContent key={index}>
                  <Styled.CategoryWrapper>
                    <Styled.CategoryHeader>
                      {RULE_MANAGER.EDIT_RULES.RULE_REQUIREMENT}
                    </Styled.CategoryHeader>
                    <Styled.CategoryValue>
                      {toSentenceCase(pf.productFeed)}
                    </Styled.CategoryValue>
                    {pf.required && (
                      <Styled.CheckboxStyled
                        label="Required"
                        checked
                        onChange={() => undefined}
                        disabled
                        theme="secondary"
                      />
                    )}
                  </Styled.CategoryWrapper>

                  <Styled.CategoryWrapper>
                    <Styled.CategoryHeader>
                      {RULE_MANAGER.EDIT_RULES.PRODUCT_FEED_DATA}
                    </Styled.CategoryHeader>
                    {editedRule.applicableProducts === ALL_PRODUCTS
                      ? (
                        <Styled.DetailsButton
                          onClick={() => productFeedHook.hookSetParametersOpen(true, pf.productFeed)}
                          loading={productFeedHook.hookFeedLoading}
                          disabled={productFeedHook.hookFeedLoading || !!productFeedHook.hookErrorMessage}
                          theme="quinary"
                        >
                          {ENUM.VIEW_PRODUCT_FEED_BUTTON}
                        </Styled.DetailsButton>
                      ) : (
                        <Styled.CategoryValue>
                          {formatFeedItem(pf.productFeedData, pf.productFeed)}
                        </Styled.CategoryValue>
                      )}
                  </Styled.CategoryWrapper>
                </Styled.RuleCriteriaContent>
              ))}
            </Styled.RuleCriteriaContentWrapper>
          )}
        </Styled.EditRuleWrapper>

      </Styled.WrapperStyled>

      <DeleteRuleModal hook={{ ...hook, isReadOnlyList }} />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen && editedRule !== null}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={() => {
          if (editedRule && initialRuleState) {
            hook.saveEditRule(editedRule, initialRuleState);
            setIsConfirmationModalOpen(false);
          }
        }}
        isLoading={hook.saveRuleLoading}
        onCloseText={RULE_MANAGER.EDIT_RULES.CANCEL_BUTTON}
        onConfirmText={RULE_MANAGER.EDIT_RULES.SAVE_BUTTON}
        title={RULE_MANAGER.EDIT_RULES.SAVE_BUTTON}
        desc={RULE_MANAGER.EDIT_RULES.RULE_UPDATED_WARNING}
        width="500px"
      />

      <ProductFeedModal
        closeModalFn={() => productFeedHook.hookSetParametersOpen(false)}
        isOpen={productFeedHook.hookParametersOpen}
        tableData={productFeedHook.hookTableData}
      />

    </AppWrapper>
  );
};

export default FintelCheckEditRule;
