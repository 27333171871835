import React, { ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as Styled from '../../styles';
import {
  Button, Modal, InputText,
} from '../../../../../../../../components';
import {
  CustomTextListType, IndexedObject,
} from '../../types';
import { STEP_TWO_MODAL, INFO } from '../../enums';
import RuleStatusSettingsComponent from '../../../RuleStatusSettingsComponent';
import { RuleStatusSettingsType } from '../../../RuleStatusSettingsComponent/enums';
import { RULE_MANAGER } from '../../../../enums';

type AddRuleStepTwoProps = {
  isOpen: boolean
  backButtonHandler: () => void
  customTextList: CustomTextListType[]
  stepTwoErrors: IndexedObject
  onBlurCheck: () => void
  updateCustomTextValue: (id: number, value: ChangeEvent<HTMLInputElement>) => void
  removeCustomTextById: (id: number) => void
  cancelButtonHandler: () => void
  navigateModalThree: () => void
  addNewCustomText: () => void
  canContinueTwo: boolean
  canAddMoreCustomText: boolean
  uniqueErrors: string
  ruleStatusSettings: RuleStatusSettingsType
  setRuleStatusSettings: (value: RuleStatusSettingsType) => void
}

export const AddRuleStepTwoModal = ({
  isOpen,
  backButtonHandler,
  uniqueErrors,
  customTextList,
  addNewCustomText,
  stepTwoErrors,
  onBlurCheck,
  removeCustomTextById,
  canAddMoreCustomText,
  updateCustomTextValue,
  cancelButtonHandler,
  navigateModalThree,
  canContinueTwo,
  ruleStatusSettings,
  setRuleStatusSettings,
}: AddRuleStepTwoProps) => (
  <Modal isOpen={isOpen}>
    <Styled.ModalWrapperStyled>
      <Styled.PageTitleStyled>
        <Styled.BackIconStyled
          onClick={backButtonHandler}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Styled.BackIconStyled>
        {STEP_TWO_MODAL.TITLE}
      </Styled.PageTitleStyled>
      <Styled.UniqueValuesMessage>
        {uniqueErrors}
      </Styled.UniqueValuesMessage>

      <Styled.RuleStatusWrapper>
        <Styled.RuleStatusLabel>
          {RULE_MANAGER.EDIT_RULES.RULE_STATUS}
          <Styled.RequiredStyled>*</Styled.RequiredStyled>
        </Styled.RuleStatusLabel>
        <Styled.RuleStatusDescription>
          {RULE_MANAGER.EDIT_RULES.RULE_STATUS_SETTINGS_DESCRIPTION}
        </Styled.RuleStatusDescription>
        <RuleStatusSettingsComponent
          ruleStatusSettings={ruleStatusSettings}
          setRuleStatusSettings={setRuleStatusSettings}
          errors={stepTwoErrors}
        />
      </Styled.RuleStatusWrapper>

      <Styled.CustomTextWraper>
        <Styled.InfoWrapper>
          <Styled.InfoRow>
            {INFO.TEXT}
          </Styled.InfoRow>
        </Styled.InfoWrapper>
        {customTextList.map((customText, index) => (
          <React.Fragment key={customText.id}>
            <Styled.CustomTextRowStyled>
              <InputText
                required
                type="text"
                name={`customTextData${customText.id}`}
                label={STEP_TWO_MODAL.CUSTOM_TEXT.LABEL}
                tooltip={STEP_TWO_MODAL.CUSTOM_TEXT.TOOLTIP}
                value={customText.value ?? ''}
                onChange={(value) => updateCustomTextValue(customText.id, value)}
                error={stepTwoErrors[customText.id]}
                onBlur={onBlurCheck}
                maxLength={500}
              />
              <Styled.DeleteRequirementButton
                onClick={() => removeCustomTextById(customText.id)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Styled.DeleteRequirementButton>
            </Styled.CustomTextRowStyled>
          </React.Fragment>
        ))}
      </Styled.CustomTextWraper>
      <Styled.AddRuleButtonWrapper>
        <Button
          theme="tertiary"
          onClick={addNewCustomText}
          width="auto"
          disabled={!canAddMoreCustomText}
        >
          <FontAwesomeIcon
            icon={faPlus}
            style={{ paddingRight: '8px' }}
          />
          {STEP_TWO_MODAL.ADD_CUSTOM_TEXT}
        </Button>
      </Styled.AddRuleButtonWrapper>
      <Styled.InfoRowBold>
        {INFO.WARNING}
      </Styled.InfoRowBold>
      <Styled.ModalFooterStyled>
        <Styled.PageCounterStyled>
          {STEP_TWO_MODAL.STEP_COUNTER}
        </Styled.PageCounterStyled>
        <Styled.NavButtonsWrapperStyled>
          <Button
            theme="secondary"
            onClick={cancelButtonHandler}
            width="100px"
          >
            {STEP_TWO_MODAL.CANCEL_BUTTON}
          </Button>
          <Button
            theme="primary"
            onClick={navigateModalThree}
            disabled={!canContinueTwo}
            width="120px"
          >
            {STEP_TWO_MODAL.CONTINUE_BUTTON}
          </Button>
        </Styled.NavButtonsWrapperStyled>
      </Styled.ModalFooterStyled>
    </Styled.ModalWrapperStyled>
  </Modal>
);
