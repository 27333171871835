export const RULE_SUMMARY_DASHBOARD = {
  URLS_MONITORED: 'URLs Monitored',
  URLS_INCOMPLETE: 'URLs Incomplete',
  PASSED: 'Passed',
  FAILED: 'Failed',
  INCOMPLETE: 'Incomplete',
  CHECKS: 'Checks',
  SET_CLOSE_CHECKS: 'Set all to Closed',
  CONFIRMATION_MODAL: {
    TITLE: 'Are you sure you want to close all checks?',
    DESCRIPTION_START: 'This action will set ',
    DESCRIPTION_END: ' records to "Closed" status.',
    CONFIRM: 'Submit',
    CANCEL: 'Cancel',
  },
};
