/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Styled from 'styled-components';
import { Button, Checkbox } from '../../../../../../components';
import { colors, fonts } from '../../../../../../styles/theme';
import { ScreenShotCell } from './Component/ScreenShotCell/index';
import { PageUrlCell } from './Component';
import * as CStyled from '../styles';
import { MonitoringReportType } from '../graphql/queries/getMonitoringReport';

const CellWrapperStyled = Styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  ${fonts.lato.H14};
  color: ${colors.color1};
  max-height: 3.75rem;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const ButtonStyled = Styled(Button)`
  color: ${colors.color12};
  white-space: pre-wrap;
  line-height: 1.5;
  padding: 0;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  :hover {
    color: ${colors.color2};
  }
`;

type Totals = {
  pageCount: number | null
  passCount: number | null
  failCount: number | null
  reviewCount: number | null
  incompleteCount: number | null
  nfCount: number | null
}

const viewHistoryCell = (row: MonitoringReportType, viewHistory: (row: MonitoringReportType) => void) => (
  <CellWrapperStyled>
    <ButtonStyled
      theme="text-only"
      onClick={(e) => { e.stopPropagation(); viewHistory(row); }}
    >
      View History
    </ButtonStyled>
  </CellWrapperStyled>
);

export const columns = (viewHistory: (row: MonitoringReportType) => void, totals?: Totals | undefined, handleCheckbox?: any, handleOnScreenshotClick?: any): TableColumn[] => {
  const theColumns: TableColumn[] = [
    {
      dataField: 'checked',
      text: '',
      width: '2.5rem',
      isCheckBox: true,
      formatter: (val, row) => (
        <div
          onClick={(e) => e.stopPropagation()}
        >
          <Checkbox
            key={val}
            checked={val}
            onChange={(checked) => handleCheckbox && handleCheckbox(checked, row, false)}
          />
        </div>
      ),
    },
    {
      dataField: 'reviewStatus',
      text: 'Review Status',
      width: '8.75rem',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'reviewDate',
      text: 'Review Date',
      width: '8.75rem',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'previousReviewStatus',
      text: 'Previous Review Status',
      width: '10rem',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'ruleStatus',
      text: 'Rule Status',
      width: '8.75rem',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'urlSearch',
      text: 'Url',
      width: '15.5rem',
      formatter: (val) => <PageUrlCell val={val} />,
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'ruleName',
      text: 'Rule Name',
      width: '18.75rem',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: 'ruleGroup',
      text: 'Rule Group',
      width: '9.75rem',
      formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      dataField: '',
      text: 'Screenshot',
      width: '9.375rem',
      formatter: (_, row) => (
        <CStyled.InternalUrlLinkStyled onClick={(e) => e.stopPropagation()}>
          <div
            onClick={(e) => handleOnScreenshotClick(row.id)}
          >
            View Screenshot
          </div>
        </CStyled.InternalUrlLinkStyled>
      ),
    },
    {
      dataField: '',
      text: 'History',
      width: '8rem',
      formatter: (_, row) => viewHistoryCell(row, viewHistory),
    },
    {
      dataField: 'publisher',
      text: 'Publishers',
      width: '17rem',
      formatter: (value) => (<CellWrapperStyled><span>{value}</span></CellWrapperStyled>),
      sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    },
  ];
  return theColumns;
};
