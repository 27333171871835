/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Styled from 'styled-components';
import { colors } from '../../../../../../styles/theme';
import * as CStyled from '../../MonitoringSummaryReport/styles';
import { PageUrlCell } from '../../MonitoringSummaryReport/contracts/Component/PageUrlCell';

const CellWrapperStyled = Styled.div`
  color: ${colors.color1};
  height: 1.6rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const columns = (handleOnScreenshotClick?: any) : TableColumn[] => [
  {
    dataField: 'createDatetime',
    text: 'Check Date',
    width: '12rem',
    formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: 'reviewStatus',
    text: 'Review Status',
    width: '12rem',
    formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: 'ruleStatus',
    text: 'Status',
    width: '8rem',
    formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: 'urlSearch',
    text: 'Url',
    width: '25rem',
    formatter: (val) => <PageUrlCell val={val} />,
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: 'ruleName',
    text: 'Rule',
    width: '12rem',
    formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: 'ruleGroup',
    text: 'Rule Group',
    width: '12rem',
    formatter: (value) => (<CellWrapperStyled>{value}</CellWrapperStyled>),
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    dataField: '',
    text: 'Screenshot',
    width: '9.375rem',
    formatter: (_, row) => (
      <CStyled.InternalUrlLinkStyled onClick={(e) => e.stopPropagation()}>
        <div
          onClick={(e) => handleOnScreenshotClick(row.id)}
        >
          View Screenshot
        </div>
      </CStyled.InternalUrlLinkStyled>
    ),
  },
] as TableColumn[];
