import React from 'react';
import {
  RouterProvider, Route, Outlet, createBrowserRouter, createRoutesFromElements, useNavigate,
} from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { MERCHANT_PREFIX, path, PUBLISHER_PREFIX } from './utils';
import InvalidUser from './pages/InvalidUser';
import Unverified from './pages/Unverified';
import P404 from './pages/404';
import Dashboard from './pages/Dashboard';
import RedirectToDashboard from './pages/RedirectToDashboard';
import AdSearch from './pages/Ads/AdSearch';
import CampaignManagement from './pages/Ads/CampaignManagement';
import PerfomanceReport from './pages/Reports/PerfomanceReport';
import TransactionMasterReport from './pages/Reports/TransactionMasterReport';
import ManageSavedReports from './pages/Reports/ManageSavedReports';
// Hidden Refer to ticket FIN-2492
// import FintelCheckPlans from './pages/FintelCheck/FintelCheckPlans';
import LinkManager from './pages/FintelCheck/LinkManager';
import NewSiteMonitoring from './pages/FintelCheck/NewSiteMonitoring';
import NewCheckReport from './pages/FintelCheck/NewCheckReport';
import PendingTransactions from './pages/Transactions/PendingTransactions';
import AddTransactions from './pages/Transactions/AddTransactions';
import GeneratePayments from './pages/Payments/GeneratePayments';
import ProcessPayments from './pages/Payments/ProcessPayments';
import PaymentReport from './pages/Payments/PaymentReport';
import CurrencyRates from './pages/CurrencyRates';
import ManualPublisherBonuses from './pages/Payments/ManualPublisherBonuses';
import AccountBalanceManagement from './pages/Payments/AccountBalanceManagement';
import UserManagement from './pages/Settings/UserManagement';
import UserProfileManagement from './pages/Settings/UserProfileManagement';
import PublisherInvitesManage from './pages/Settings/PublisherInvitesManage';
import SecurityPermission from './pages/Settings/SecurityPermissions';
import MerchantAccountDetails from './pages/Merchants/Account/MerchantAccountDetails';
import MerchantAccountBalance from './pages/Merchants/Account/MerchantAccountBalance';
import ProgramDetails from './pages/Publishers/ProgramDetails';
import ProductDetails from './pages/Publishers/ProgramDetails/ProductDetails';
import NewProduct from './pages/Merchants/Program/newProduct';
import ProductCatalog from './pages/Merchants/Program/ProductCatalog';
import EditProduct from './pages/Merchants/Program/ProductCatalog/EditProduct';
import ManageTrackingTransactionSettings from './pages/Merchants/Program/ManageTrackingTransactionSettings';
import ManageCommissions from './pages/Merchants/Commissions/ManageCommissions';
import MembershipManagement from './pages/Merchants/Publishers/MembershipManagement';
import PublisherInvitation from './pages/Merchants/Publishers/PublisherInvitation';
import SendPublisherInvitation from './pages/Merchants/Publishers/SendPublisherInvitation';
import PublisherSearch from './pages/Merchants/Publishers/PublisherSearch';
import PublisherGroups from './pages/Merchants/Publishers/PublisherGroups';
import InternalPublisher from './pages/Merchants/Publishers/InternalPublisher';
import FintelCheckRuleManager from './pages/Merchants/FintelCheck/FintelCheckRuleManager';
import FintelCheckSettings from './pages/Merchants/FintelCheck/FintelCheckSettings';
import AccountDetails from './pages/Publishers/Account/AccountDetails';
import TrackingDetails from './pages/Publishers/Account/TrackingDetails';
import PostbackDetails from './pages/PostbackDetails/PostbackDetails';
import PublisherOverview from './pages/Publishers/PublisherOverview';
import Memberships from './pages/Publishers/Merchant/Memberships';
import SearchPublisherPrograms from './pages/Publishers/Merchant/SearchPrograms';
import ProductFeed from './pages/Publishers/Ads/ProductFeed';
import ActiveCommissions from './pages/Publishers/ActiveCommissions';
import EditCommissions from './pages/Merchants/Commissions/EditCommissions';
import MerchantManagement from './pages/Merchants/Management';
import PublisherManagement from './pages/Publishers/PublisherSearch';
import Callback from './pages/Callback';
import Login from './pages/Login';
import AdBanner from './pages/Ads/NewAd/Banner';
import InvitationTemplate from './pages/Merchants/Publishers/InvitationTemplate';
import InvitationHistory from './pages/Merchants/Publishers/InvitationHistory';
import CreatePublisherGroup from './pages/Merchants/Publishers/CreatePublisherGroup';
import ProgramSettings from './pages/Merchants/Program/ProgramSettings';
import FintelCheckPublisherSearch from './pages/Merchants/FintelCheck/FintelCheckPublisherSearch';
import CreateCampaign from './pages/Merchants/Ads/CreateCampaign';
import EditCampaign from './pages/Merchants/Ads/EditCampaign';
import { ActAsProvider } from './context';
import UserInfo from './pages/Publishers/SignUp/UserInfo';
import ContactInfo from './pages/Publishers/SignUp/ContactInfo';
import Publisher from './pages/Publishers/SignUp/Publisher';
import PaymentInfo from './pages/Publishers/SignUp/PaymentInfo';
import MerchantProgram from './pages/Publishers/SignUp/MerchantProgram';
import Confirmation from './pages/Publishers/SignUp/Confirmation';
import MerchantDashboard from './pages/Merchants/Dashboard';
import PublisherDashboard from './pages/Publishers/Dashboard';
import AdTextLink from './pages/Ads/NewAd/TextLink';
import EditAd from './pages/Ads/AdEdit';
import AdHtml from './pages/Ads/NewAd/Html';
import NewMerchant from './pages/Merchants/newMerchant';
import ForgotPassword from './pages/ForgotPassword';
import AddNewUser from './pages/Settings/AddNewUser';
import AccountBalanceManagementStats from './pages/Payments/AccountBalanceManagementStats';
import AccountBalanceManagementDetails from './pages/Payments/AccountBalanceManagementDetails';
import AccountBalanceManagementNewStats from './pages/Payments/AccountBalanceManagementUpdateStats';
import FintelCheckBlacklist from './pages/Merchants/FintelCheck/FintelCheckBlacklist';
import NewCommission from './pages/Merchants/Commissions/NewCommission';
import FintelCheckManualUrls from './pages/Merchants/FintelCheck/FintelCheckManualUrls';
import FintelCheckReport from './pages/Merchants/FintelCheck/FintelCheckReport';
import MonitoringRulesReport from './pages/Merchants/FintelCheck/MonitoringRulesReport';
import InvoiceStatement from './pages/Payments/InvoiceStatement';
import PaymentReportDetails from './pages/Payments/ReportDetails';
import DetailedPaymentRecords from './pages/Payments/ReportDetails/DetailedPaymentRecords';
import FintelCheckHistory from './pages/Merchants/FintelCheck/FintelCheckHistory';
import FintelCheckDetails from './pages/Merchants/FintelCheck/FintelCheckDetails';
import FintelCheckRuleDetails from './pages/Merchants/FintelCheck/FintelCheckRuleDetails';
import FintelCheckChangeHistory from './pages/Merchants/FintelCheck/FintelCheckChangeHistory';
import ProductFeedDetails from './pages/Publishers/Ads/ProductFeed/ProductFeedDetails';
import { FintelCheckContext } from './pages/Merchants/FintelCheck/Context';
import FintelCheckHistoryReport from './pages/Merchants/FintelCheck/FintelCheckHistoryReport';
import { ReportDetailsContext } from './pages/Payments/ReportDetails/context/reportDetailsContext';
import MembershipDetails from './pages/Merchants/Program/MembershipDetails';
import { SignUpContext } from './pages/Publishers/SignUp/Context';
import { EditSavedReports } from './pages/Reports/EditSavedReport';
import EditPublisherGroup from './pages/Merchants/Publishers/EditPublisherGroup';
import { Notifications } from './pages/Notifications';
import SetPassword from './pages/SetPassword';
import { NotificationsContext } from './components/Notifications/context/notificationsContext';
import { EmailVerified } from './pages/EmailVerified';
import Maintenance from './pages/Maintenance';
import { LoadingPage } from './components';
import { CHECK_MAINTENANCE } from './pages/Maintenance/graphql';
import ChangeMonitoring from './pages/Merchants/FintelCheck/ChangeMonitoring';
import ChangeMonitoringDetails from './pages/Merchants/FintelCheck/ChangeMonitoring/ChangeMonitoringDetails';
import FintelCheckEditRule from './pages/Merchants/FintelCheck/FintelCheckRuleManager/pages/FintelCheckEditRule';

function Layout() {
  return (
    <ActAsProvider>
      <SignUpContext>
        <FintelCheckContext>
          <ReportDetailsContext>
            <NotificationsContext>
              <Outlet />
            </NotificationsContext>
          </ReportDetailsContext>
        </FintelCheckContext>
      </SignUpContext>
    </ActAsProvider>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      {/* ------------- ADMIN ROUTES ---------------- */}
      <Route path="/">
        <Route
          index
          element={<RedirectToDashboard to="/dashboard" />}
        />
        <Route
          path={path.dashboard.href}
          element={<Dashboard permissionsCodeList={path.dashboard.adminCode} />}
        />
        <Route
          path={path.notifications.href}
          element={<Notifications permissionsCodeList={path.userProfileManagement.adminCode} />}
        />
        <Route
          path={path.userProfileManagement.href}
          element={(
            <UserProfileManagement
              permissionsCodeList={path.userProfileManagement.adminCode}
              isSettings={false}
            />
          )}
        />
        <Route
          path={path.userAccountManagement.href}
          element={(
            <UserProfileManagement
              permissionsCodeList={path.userAccountManagement.adminCode}
              isSettings
            />
          )}
        />
        <Route
          path={path.adSearch.href}
          element={<AdSearch permissionsCodeList={path.adSearch.adminCode} />}
        />
        <Route
          path={path.campaignManagement.href}
          element={<CampaignManagement permissionsCodeList={path.campaignManagement.adminCode} />}
        />
        <Route
          path={path.performanceReport.href}
          element={<PerfomanceReport permissionsCodeList={path.performanceReport.adminCode} />}
        />
        <Route
          path={path.transactionMaster.href}
          element={<TransactionMasterReport permissionsCodeList={path.transactionMaster.adminCode} />}
        />
        <Route
          path={path.manageSavedReports.href}
          element={<ManageSavedReports permissionsCodeList={path.manageSavedReports.adminCode} />}
        />
        <Route
          path={path.editSavedReports.href}
          element={<EditSavedReports permissionsCodeList={path.editSavedReports.adminCode} />}
        />
        <Route
          path={path.linkManager.href}
          element={<LinkManager permissionsCodeList={path.linkManager.adminCode} />}
        />
        <Route
          path={path.newSiteMonitoring.href}
          element={<NewSiteMonitoring permissionsCodeList={path.newSiteMonitoring.adminCode} />}
        />
        <Route
          path={path.adminCheckReport.href}
          element={<NewCheckReport permissionsCodeList={path.adminCheckReport.adminCode} />}
        />
        <Route
          path={path.monitoringRulesReport.href}
          element={<MonitoringRulesReport permissionsCodeList={path.monitoringRulesReport.adminCode} />}
        />
        <Route
          path={path.pendingTransactions.href}
          element={<PendingTransactions permissionsCodeList={path.pendingTransactions.adminCode} />}
        />
        <Route
          path={path.addTransactions.href}
          element={<AddTransactions permissionsCodeList={path.addTransactions.adminCode} />}
        />
        <Route
          path={path.generatePayment.href}
          element={<GeneratePayments permissionsCodeList={path.generatePayment.adminCode} />}
        />
        <Route
          path={path.processPayment.href}
          element={<ProcessPayments permissionsCodeList={path.processPayment.adminCode} />}
        />
        <Route
          path={path.paymentReport.href}
          element={<PaymentReport permissionsCodeList={path.paymentReport.adminCode} />}
        />
        <Route
          path={path.paymentReportDetails.href}
          element={<PaymentReportDetails permissionsCodeList={path.paymentReportDetails.adminCode} />}
        />
        <Route
          path={path.detailedPaymentRecords.href}
          element={<DetailedPaymentRecords permissionsCodeList={path.detailedPaymentRecords.adminCode} />}
        />
        <Route
          path={path.currencyRates.href}
          element={<CurrencyRates permissionsCodeList={path.currencyRates.adminCode} />}
        />
        <Route
          path={path.manualPublisherBonuses.href}
          element={<ManualPublisherBonuses permissionsCodeList={path.manualPublisherBonuses.adminCode} />}
        />
        <Route
          path={path.merchantAccountBalanceManagement.href}
          element={<AccountBalanceManagement permissionsCodeList={path.merchantAccountBalanceManagement.adminCode} />}
        />
        <Route
          path={path.merchantAccountBalanceStats.href}
          element={<AccountBalanceManagementStats permissionsCodeList={path.merchantAccountBalanceStats.adminCode} />}
        />
        <Route
          path={path.merchantAccountBalanceManagementDetails.href}
          element={<AccountBalanceManagementDetails permissionsCodeList={path.merchantAccountBalanceManagementDetails.adminCode} />}
        />
        <Route
          path={path.invoiceStatement.href}
          element={<InvoiceStatement permissionsCodeList={path.invoiceStatement.adminCode} />}
        />
        <Route
          path={path.merchantAccountBalanceManagementNewStats.href}
          element={<AccountBalanceManagementNewStats permissionsCodeList={path.merchantAccountBalanceManagementNewStats.adminCode} />}
        />
        <Route
          path={path.userManagement.href}
          element={<UserManagement permissionsCodeList={path.userManagement.adminCode} />}
        />
        <Route
          path={path.publisherInvites.href}
          element={<PublisherInvitesManage permissionsCodeList={path.publisherInvites.adminCode} />}
        />
        <Route
          path={path.securityPermissions.href}
          element={<SecurityPermission permissionsCodeList={path.securityPermissions.adminCode} />}
        />
        <Route
          path={path.addNewMerchant.href}
          element={<NewMerchant permissionsCodeList={path.addNewMerchant.adminCode} />}
        />
        <Route
          path={path.merchantManagement.href}
          element={<MerchantManagement permissionsCodeList={path.merchantManagement.adminCode} />}
        />
        <Route
          path={path.merchantManagementDetail.href}
          element={(
            <MerchantAccountDetails
              isAdmin
              permissionsCodeList={path.merchantManagementDetail.adminCode}
            />
          )}
        />
        <Route
          path={path.publisherSearch.href}
          element={<PublisherManagement permissionsCodeList={path.publisherSearch.adminCode} />}
        />
        <Route
          path={path.publisherSearchDetail.href}
          element={(
            <AccountDetails
              isAdmin
              permissionsCodeList={path.publisherSearchDetail.adminCode}
            />
          )}
        />
        <Route
          path={path.addNewAdBanner.href}
          element={<AdBanner permissionsCodeList={path.addNewAdBanner.adminCode} />}
        />
        <Route
          path={path.addNewAdLink.href}
          element={<AdTextLink permissionsCodeList={path.addNewAdLink.adminCode} />}
        />
        <Route
          path={path.editAd.href}
          element={<EditAd permissionsCodeList={path.editAd.adminCode} />}
        />
        <Route
          path={path.addNewAdHtml.href}
          element={<AdHtml permissionsCodeList={path.addNewAdHtml.adminCode} />}
        />
        <Route
          path={path.addNewUser.href}
          element={<AddNewUser permissionsCodeList={path.addNewUser.adminCode} />}
        />
        <Route
          path={path.createCampaign.href}
          element={<CreateCampaign permissionsCodeList={path.createCampaign.adminCode} />}
        />
        <Route
          path={path.editCampaign.href}
          element={<EditCampaign permissionsCodeList={path.editCampaign.adminCode} />}
        />
      </Route>

      {/* ------------- MERCHANT ROUTES ---------------- */}
      <Route path={MERCHANT_PREFIX}>
        <Route
          index
          element={<RedirectToDashboard to={`${MERCHANT_PREFIX}${path.dashboard.href}`} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.dashboard.href}`}
          element={<MerchantDashboard permissionsCodeList={path.dashboard.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.accountDetails.href}`}
          element={(
            <MerchantAccountDetails
              isAdmin={false}
              permissionsCodeList={path.accountDetails.merchantCode}
            />
          )}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.postbackDetails.href}`}
          element={(
            <PostbackDetails
              companyType="Merchant"
              targetType="Publisher"
              permissionsCodeList={path.postbackDetails.merchantCode}
            />
          )}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.accountBalance.href}`}
          element={<MerchantAccountBalance permissionsCodeList={path.accountBalance.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.merchantBalanceManagementDetails.href}`}
          element={<AccountBalanceManagementDetails permissionsCodeList={path.accountBalance.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.merchantInvoiceStatement.href}`}
          element={<InvoiceStatement permissionsCodeList={path.accountBalance.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.programDetails.href}`}
          element={<ProgramDetails permissionsCodeList={path.programDetails.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.productDetails.href}`}
          element={<ProductDetails permissionsCodeList={path.productDetails.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.addNewProduct.href}`}
          element={<NewProduct permissionsCodeList={path.addNewProduct.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.productCatalog.href}`}
          element={<ProductCatalog permissionsCodeList={path.productCatalog.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.editProduct.href}`}
          element={<EditProduct permissionsCodeList={path.editProduct.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.trackingSettings.href}`}
          element={<ManageTrackingTransactionSettings permissionsCodeList={path.trackingSettings.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.membershipManagement.href}`}
          element={<MembershipManagement permissionsCodeList={path.membershipManagement.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.publisherInvitations.href}`}
          element={<PublisherInvitation permissionsCodeList={path.publisherInvitations.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.sendPublisherInvitation.href}`}
          element={<SendPublisherInvitation permissionsCodeList={path.sendPublisherInvitation.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.publisherSearch.href}`}
          element={<PublisherSearch permissionsCodeList={path.publisherSearch.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.publisherGroups.href}`}
          element={<PublisherGroups permissionsCodeList={path.publisherGroups.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.internalPublisher.href}`}
          element={<InternalPublisher permissionsCodeList={path.internalPublisher.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.adSearch.href}`}
          element={<AdSearch permissionsCodeList={path.adSearch.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.campaignManagement.href}`}
          element={<CampaignManagement permissionsCodeList={path.campaignManagement.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.performanceReport.href}`}
          element={<PerfomanceReport permissionsCodeList={path.performanceReport.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.transactionMaster.href}`}
          element={<TransactionMasterReport permissionsCodeList={path.transactionMaster.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.paymentReport.href}`}
          element={<PaymentReport permissionsCodeList={path.paymentReport.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.paymentReportDetails.href}`}
          element={<PaymentReportDetails permissionsCodeList={path.paymentReportDetails.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.detailedPaymentRecords.href}`}
          element={<DetailedPaymentRecords permissionsCodeList={path.detailedPaymentRecords.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.manageSavedReports.href}`}
          element={<ManageSavedReports permissionsCodeList={path.manageSavedReports.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckReport.href}`}
          element={<FintelCheckReport permissionsCodeList={path.fintelCheckReport.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.monitoringRulesReport.href}`}
          element={<MonitoringRulesReport permissionsCodeList={path.monitoringRulesReport.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckHistoryReport.href}`}
          element={<FintelCheckHistoryReport permissionsCodeList={path.fintelCheckHistoryReport.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.ruleManager.href}`}
          element={<FintelCheckRuleManager permissionsCodeList={path.ruleManager.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckSettings.href}`}
          element={<FintelCheckSettings permissionsCodeList={path.fintelCheckSettings.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckPublisherSearch.href}`}
          element={<FintelCheckPublisherSearch permissionsCodeList={path.fintelCheckPublisherSearch.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckBlacklist.href}`}
          element={<FintelCheckBlacklist permissionsCodeList={path.fintelCheckBlacklist.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckManualUrlsList.href}`}
          element={<FintelCheckManualUrls permissionsCodeList={path.fintelCheckManualUrlsList.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckHistory.href}`}
          element={<FintelCheckHistory permissionsCodeList={path.fintelCheckHistory.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckDetails.href}`}
          element={<FintelCheckDetails permissionsCodeList={path.fintelCheckDetails.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckRuleDetails.href}`}
          element={<FintelCheckRuleDetails permissionsCodeList={path.fintelCheckRuleDetails.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckEditRule.href}`}
          element={<FintelCheckEditRule permissionsCodeList={path.fintelCheckEditRule.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckChangeHistory.href}`}
          element={<FintelCheckChangeHistory permissionsCodeList={path.fintelCheckChangeHistory.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.pendingTransactions.href}`}
          element={<PendingTransactions permissionsCodeList={path.pendingTransactions.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.addTransactions.href}`}
          element={<AddTransactions permissionsCodeList={path.addTransactions.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.addNewAdBanner.href}`}
          element={<AdBanner permissionsCodeList={path.addNewAdBanner.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.addNewAdHtml.href}`}
          element={<AdHtml permissionsCodeList={path.addNewAdHtml.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.addNewAdLink.href}`}
          element={<AdTextLink permissionsCodeList={path.addNewAdLink.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.editAd.href}`}
          element={<EditAd permissionsCodeList={path.editAd.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.invitationTemplate.href}`}
          element={<InvitationTemplate permissionsCodeList={path.invitationTemplate.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.invitationHistory.href}`}
          element={<InvitationHistory permissionsCodeList={path.invitationHistory.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.addPublisherGroup.href}`}
          element={<CreatePublisherGroup permissionsCodeList={path.addPublisherGroup.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.editPublisherGroup.href}`}
          element={<EditPublisherGroup permissionsCodeList={path.addPublisherGroup.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.programSettings.href}`}
          element={<ProgramSettings permissionsCodeList={path.programSettings.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.manageCommissions.href}`}
          element={<ManageCommissions permissionsCodeList={path.manageCommissions.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.addNewCommission.href}`}
          element={<NewCommission permissionsCodeList={path.addNewCommission.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.editCommissions.href}`}
          element={<EditCommissions permissionsCodeList={path.editCommissions.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.createCampaign.href}`}
          element={<CreateCampaign permissionsCodeList={path.createCampaign.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.editCampaign.href}`}
          element={<EditCampaign permissionsCodeList={path.editCampaign.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.membershipDetails.href}`}
          element={<MembershipDetails permissionsCodeList={path.membershipDetails.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.linkManager.href}`}
          element={<LinkManager permissionsCodeList={path.linkManager.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.newSiteMonitoring.href}`}
          element={<NewSiteMonitoring permissionsCodeList={path.newSiteMonitoring.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckChangeMonitoring.href}`}
          element={<ChangeMonitoring permissionsCodeList={path.fintelCheckChangeMonitoring.merchantCode} />}
        />
        <Route
          path={`${MERCHANT_PREFIX}${path.fintelCheckChangeMonitoringDetails.href}`}
          element={<ChangeMonitoringDetails permissionsCodeList={path.fintelCheckChangeMonitoringDetails.merchantCode} />}
        />
      </Route>

      {/* ------------- PUBLISHER ROUTES ---------------- */}
      <Route path={PUBLISHER_PREFIX}>
        <Route
          index
          element={<RedirectToDashboard to={`${PUBLISHER_PREFIX}${path.dashboard.href}`} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.dashboard.href}`}
          element={<PublisherDashboard permissionsCodeList={path.dashboard.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.accountDetails.href}`}
          element={(
            <AccountDetails
              isAdmin={false}
              permissionsCodeList={path.accountDetails.publisherCode}
            />
          )}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.trackingDetails.href}`}
          element={<TrackingDetails permissionsCodeList={path.trackingDetails.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.postbackDetails.href}`}
          element={(
            <PostbackDetails
              companyType="Publisher"
              targetType="Merchant"
              permissionsCodeList={path.postbackDetails.publisherCode}
            />
          )}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.overview.href}`}
          element={<PublisherOverview permissionsCodeList={path.overview.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.memberships.href}`}
          element={<Memberships permissionsCodeList={path.memberships.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.searchProgram.href}`}
          element={<SearchPublisherPrograms permissionsCodeList={path.searchProgram.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.programDetails.href}`}
          element={<ProgramDetails permissionsCodeList={path.programDetails.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.activeComissions.href}`}
          element={<ActiveCommissions permissionsCodeList={path.activeComissions.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.adSearch.href}`}
          element={<AdSearch permissionsCodeList={path.adSearch.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.productFeed.href}`}
          element={<ProductFeed permissionsCodeList={path.productFeed.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.productFeedDetails.href}`}
          element={<ProductFeedDetails permissionsCodeList={path.productFeedDetails.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.performanceReport.href}`}
          element={<PerfomanceReport permissionsCodeList={path.performanceReport.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.transactionMaster.href}`}
          element={<TransactionMasterReport permissionsCodeList={path.transactionMaster.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.paymentReport.href}`}
          element={<PaymentReport permissionsCodeList={path.paymentReport.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.paymentReportDetails.href}`}
          element={<PaymentReportDetails permissionsCodeList={path.paymentReportDetails.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.detailedPaymentRecords.href}`}
          element={<DetailedPaymentRecords permissionsCodeList={path.detailedPaymentRecords.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.manageSavedReports.href}`}
          element={<ManageSavedReports permissionsCodeList={path.manageSavedReports.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.linkManager.href}`}
          element={<LinkManager permissionsCodeList={path.linkManager.publisherCode} />}
        />
        <Route
          path={`${PUBLISHER_PREFIX}${path.productDetails.href}`}
          element={<ProductDetails permissionsCodeList={path.productDetails.publisherCode} />}
        />
      </Route>

      {/* ------------- GLOBAL ROUTES ---------------- */}
      <Route
        path="/callback"
        element={<Callback />}
      />
      <Route
        path="/login"
        element={<Login />}
      />
      <Route
        path={path.forgotPassword.href}
        element={<ForgotPassword />}
      />
      <Route
        path={path.setPassword.href}
        element={<SetPassword />}
      />
      <Route
        path="/invalid-user"
        element={<InvalidUser />}
      />
      <Route
        path="/unverified"
        element={<Unverified />}
      />
      <Route
        path={path.createAccountStep1.href}
        element={<UserInfo />}
      />
      <Route
        path={path.createAccountStep2.href}
        element={<ContactInfo />}
      />
      <Route
        path={path.createAccountStep3.href}
        element={<Publisher />}
      />
      <Route
        path={path.createAccountStep4.href}
        element={<PaymentInfo />}
      />
      <Route
        path={path.createAccountStep5.href}
        element={<MerchantProgram />}
      />
      <Route
        path={path.createAccountStep6.href}
        element={<Confirmation />}
      />
      <Route
        path={path.emailVerified.href}
        element={<EmailVerified />}
      />
      <Route
        path={path.maintenance.href}
        element={<Maintenance />}
      />
      <Route
        path="*"
        element={<P404 />}
      />
    </Route>,
  ),
);

const Router = () => {
  const [isUnderMaintenance, setIsUnderMaintenance] = React.useState(false);
  const [checkMaintenance, { loading: checkMaintenanceLoading }] = useLazyQuery(CHECK_MAINTENANCE);
  const checkIfUnderMaintenance = async () => {
    // Check if the app is under maintenance
    try {
      const { data, error } = await checkMaintenance();
      if (data.checkMaintenance.status === 'maintenance') {
        setIsUnderMaintenance(true);
      } else {
        setIsUnderMaintenance(false);
      }
    } catch (error) {
      setIsUnderMaintenance(false);
    }
  };

  if (checkMaintenanceLoading) return <LoadingPage />;
  if (isUnderMaintenance) return <Maintenance />;

  return (
    <RouterProvider router={router} />
  );
};

export default Router;
