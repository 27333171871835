import { gql } from '@apollo/client';

export const GET_HISTORY_REPORT_RESULTS = gql`
  query getHistoryReport($input: HistoryReportInput!) {
    historyReport(input: $input) {
      count
      totalPages
      currentPage
      historyReport {
        id
        reviewStatus
        previousReviewStatus
        reviewDate
        ruleStatus
        pageUrl
        ruleName
        ruleGroup
        createDatetime
      }
    }
  }
`;
