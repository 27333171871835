export const SUMMARY = {
  TITLE: 'Summary Report',
  GENERATE_REPORT: 'Generate Report',
  DOWNLOAD_CSV: 'Download CSV',
  DOWNLOAD_IMAGE: 'Download Image',
};

export const ALL_PRODUCTS = 'All Products';
export const ALL_STATUSES = 'All Statuses';
export const NO_PRODUCTS = 'No Products';

export const RULE_STATUSES = [
  { label: 'All Statuses', value: 'All Statuses' },
  { label: 'Pass', value: 'Pass' },
  { label: 'Fail', value: 'Fail' },
  { label: 'Check Incomplete', value: 'Check Incomplete' },
];

export const RULE_STATUS_LABEL = 'Rule Status';
export const REVIEW_STATUS_LABEL = 'Review Status';
export const RULE_GROUPS_LABEL = 'Rule Groups';
export const PUBLISHERS_LABEL = 'Publishers';

export const URL_SEARCH = {
  TITLE: 'URL Search',
  PLACEHOLDER: 'Search for URL',
};

export const CHECK_RULES_DEFAULT = { label: 'All Rules', value: 'All Rules' };
export const PUBLISHERS_DEFAULT = [
  { label: 'All Publisher and Manual Urls', value: 'All Publishers and Manual Urls' },
  { label: 'All Publisher', value: 'allpublishers' },
  { label: 'Manual Urls', value: 'manualurls' },
];

export const CHECK_RULE_GROUP_DEFAULT = [
  { label: 'All Rule Groups', value: 'All Rule Groups' },
  { label: 'No Rule Group', value: 'empty' },
];

export const RULE_REVIEW_STATUSES = [
  { label: 'All Open Statuses', value: 'All Open Statuses' },
  { label: 'New (all)', value: 'New' },
  { label: 'Brand New', value: 'Brand New' },
  { label: 'Newly Updated', value: 'Newly Updated' },
  { label: 'Internal Review', value: 'Internal Review' },
  { label: 'External Review', value: 'External Review' },
  { label: 'Closed', value: 'Closed' },
];

export const BULK_RULE_REVIEW_STATUSES = [
  { label: 'Newly Updated', value: 'Newly Updated' },
  { label: 'Internal Review', value: 'Internal Review' },
  { label: 'External Review', value: 'External Review' },
  { label: 'Closed', value: 'Closed' },
];

export const SET_REVIEW_STATUS_TITLE = 'Review Status';

export const SET_REVIEW_STATUS_BUTTON_TITLE = 'Update Status';

export const FEEDBACK_MODAL = {
  TITLE: 'Fintel Check Feedback',
  DESCRIPTION: 'Do you consider this status to be correct?',
  YES: 'Yes',
  NO: 'No',
  CANCEL: 'Cancel',
  SUBMIT: 'Submit',
};
