/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '../../../../../../hooks';
import { dateFormatter, csvGenerator } from '../../../../../../utils';
import {
  CHECK_RULE_GROUP_DEFAULT, CHECK_RULES_DEFAULT, PUBLISHERS_DEFAULT, RULE_REVIEW_STATUSES, RULE_STATUSES,
} from '../enums';
import { useFintelCheckContext } from '../../../hooks';
import { processDataToCSVString } from '../../../FintelCheckDetails/utils';
import { columns } from '../contracts/index';
import {
  GET_MONITORING_REPORT_FILTER_OPTIONS, GET_MONITORING_REPORT_RESULTS, MonitoringReportType,
} from '../graphql/queries';

export const useSummaryReport = (
  viewHistoryReport: (row: MonitoringReportType) => void,
  selectedReviewStatus: SelectOption | undefined,
  handleOnScreenshotClick: (val: any) => void,
) => {
  const { contextState, contextDispatchHandler } = useFintelCheckContext();
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();

  // Filters
  const [ruleStatus, setRuleStatus] = useState<SelectOption>(RULE_STATUSES[0]);
  const [reviewStatus, setReviewStatus] = useState<SelectOption>(RULE_REVIEW_STATUSES[0]);
  const [checkRule, setCheckRule] = useState<SelectOption>(CHECK_RULES_DEFAULT);
  const [checkRuleOptions, setCheckRuleOptions] = useState<SelectOption[]>([CHECK_RULES_DEFAULT]);
  const [ruleGroup, setRuleGroup] = useState<SelectOption>(CHECK_RULE_GROUP_DEFAULT[0]);
  const [ruleGroupOptions, setRuleGroupOptions] = useState<SelectOption[]>([CHECK_RULE_GROUP_DEFAULT[0]]);
  const [publisher, setPublisher] = useState<SelectOption>(PUBLISHERS_DEFAULT[0]);
  const [publisherOptions, setPublisherOptions] = useState<SelectOption[]>([PUBLISHERS_DEFAULT[0]]);
  const [urlSearch, setUrlSearch] = useState<string>('');
  const [getMonitoringReportFilters, { loading: getFilterOptionsLoading }] = useLazyQuery(GET_MONITORING_REPORT_FILTER_OPTIONS);

  const [records, setRecords] = useState({ label: '10', value: '10' });

  const [selectedCheckboxItem, setSelectedCheckboxItem] = useState<{ checked: boolean, selected?: MonitoringReportType }>();
  const [errorMessage, setErrorMessage] = useState('');

  // Data
  const [tableData, setTableData] = useState<any[]>();
  const [dataGenerated, setDataGenerated] = useState(false);
  const [generateClicked, setGenerateClicked] = useState(false);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [generateClickedBeforeDownload, setGenerateClickedBeforeDownload] = useState(false);
  const [getMonitoringReport, { loading: getFintelChecksLoading }] = useLazyQuery(GET_MONITORING_REPORT_RESULTS);

  // Table & Pagination
  const [tableColumns, setTableColumns] = useState<TableColumn[]>(columns(viewHistoryReport));
  const [tableKey, setTableKey] = useState<number>(123456); // Used to force table to rerender from start

  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'reviewStatus', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [refetchTable, setRefetchTable] = useState<boolean>(false);

  const getMonitoringReportData = async (limit: number, page?: null | number) => {
    const input = {
      merchantId: hookWhoAmI.companyId ?? '0',
      sortBy: sortColumn.column,
      sortDirection: sortColumn.direction ?? 'desc',
      limit,
      currentPage: page ?? currentPage,
      reviewStatus: reviewStatus.value,
      ruleStatus: (ruleStatus.value === RULE_STATUSES[0].value) ? null : ruleStatus.value,
      ruleGroup: (ruleGroup.value === CHECK_RULE_GROUP_DEFAULT[0].value) ? null : ruleGroup.value,
      ruleName: (checkRule.value === CHECK_RULES_DEFAULT.value) ? null : checkRule.value,
      publisher: (publisher.value === PUBLISHERS_DEFAULT[0].value) ? null : publisher.value,
      urlSearch: (urlSearch === '') ? null : urlSearch,
    };

    const { data, error } = await getMonitoringReport({ variables: { input }, fetchPolicy: 'no-cache' });
    if (error) {
      setErrorMessage(error.message);
      return;
    }
    return data;
  };

  const handleOnSelectCheckbox = (checked: boolean, selected?: any, isCheckAll = false) => {
    setSelectedCheckboxItem({ checked, selected });
  };

  const getMonitoringReportHandler = async () => {
    const data = await getMonitoringReportData(Number(records.value));
    setTableKey((last) => last + 1);
    if (data?.monitoringReport?.monitoringReports) {
      const formattedData = data.monitoringReport.monitoringReports.map((item: MonitoringReportType) => ({
        id: item.id,
        reviewStatus: item.reviewStatus,
        previousReviewStatus: item.previousReviewStatus,
        reviewDate: item.reviewDate ? dateFormatter(new Date(item.reviewDate), '/') : null,
        ruleStatus: item.ruleStatus,
        urlSearch: item.pageUrl,
        ruleName: item.ruleName,
        ruleGroup: item.ruleGroup,
        createDatetime: item.createDatetime ? dateFormatter(new Date(item.createDatetime), '/') : null,
        checked: false,
        publisher: item.publisher,
      }));

      setTableColumns(columns(viewHistoryReport, data.monitoringReport.totalCounts, handleOnSelectCheckbox, handleOnScreenshotClick));
      setTableData(formattedData);
      setTotalPages(data.monitoringReport.totalPages);
      setTotalRecords(data.monitoringReport.count);
      setDataGenerated(true);
    } else {
      setTableColumns(columns(viewHistoryReport));
      setTableData([]);
      setCurrentPage(1);
      setTotalPages(0);
      setTotalRecords(0);
    }
  };

  const fetchFilterOptions = async () => {
    const { data, error } = await getMonitoringReportFilters({
      variables: {
        input: {
          programId: hookWhoAmI.programId,
          merchantId: hookWhoAmI.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (error) {
      setErrorMessage(error.message);
      return;
    }

    if (data?.getMonitoringReportFilters) {
      if (data?.getMonitoringReportFilters?.rules) {
        setCheckRuleOptions([CHECK_RULES_DEFAULT, ...data.getMonitoringReportFilters.rules]);
      }
      if (data?.getMonitoringReportFilters?.ruleGroups) {
        setRuleGroupOptions([...CHECK_RULE_GROUP_DEFAULT, ...data.getMonitoringReportFilters.ruleGroups]);
      }
      if (data?.getMonitoringReportFilters?.publishers) {
        setPublisherOptions([...PUBLISHERS_DEFAULT, ...data.getMonitoringReportFilters.publishers]);
      }
    }
  };

  const generateMonitoringReport = async () => {
    setDownloadClicked(false);
    setGenerateClicked(true);
    setRefetchTable(true);
    setCurrentPage(1);
  };

  const downloadMonitoringReportCSV = async () => {
    setDownloadClicked(true);
    setGenerateClicked(false);
    setRefetchTable(true);
    setCurrentPage(1);
  };

  const downloadMonitoringReportHandler = async () => {
    const limit = 1000000; // FC-979: Limit to a large number to download all records
    const data = await getMonitoringReportData(limit, 1);
    setDownloadClicked(false);
    setTableKey((last) => last + 1);
    if (data?.monitoringReport?.monitoringReports) {
      const formattedData = data.monitoringReport.monitoringReports.map((item: MonitoringReportType) => ({
        reviewStatus: item.reviewStatus,
        previousReviewStatus: item.previousReviewStatus,
        reviewDate: item.reviewDate ? dateFormatter(new Date(item.reviewDate), '/') : null,
        ruleStatus: item.ruleStatus,
        urlSearch: item.pageUrl,
        ruleName: item.ruleName,
        ruleGroup: item.ruleGroup,
      }));

      const csvString = processDataToCSVString(formattedData, columns(viewHistoryReport, data.monitoringReport.totalCounts));
      csvGenerator(csvString, 'Fintel Check Monitoring Report');

      generateMonitoringReport();
    }
  };

  const handleUpdateSelectedCheckboxItem = () => {
    setErrorMessage('');
    if (tableData) {
      const updatedData = tableData.map((item) => (
        item.id === selectedCheckboxItem?.selected?.id
          ? { ...item, checked: selectedCheckboxItem?.checked } : item));
      setTableData(updatedData);
    }
  };

  useEffect(() => {
    if (selectedCheckboxItem) handleUpdateSelectedCheckboxItem();
  }, [selectedCheckboxItem]);

  useEffect(() => {
    setErrorMessage('');
  }, [selectedReviewStatus]);

  const clearFormHandler = async () => {
    setRuleStatus(RULE_STATUSES[0]);
    setReviewStatus(RULE_REVIEW_STATUSES[0]);
    setCheckRule(CHECK_RULES_DEFAULT);
    setRuleGroup(CHECK_RULE_GROUP_DEFAULT[0]);
    setPublisher(PUBLISHERS_DEFAULT[0]);
    setUrlSearch('');

    setRefetchTable(false);
    setDataGenerated(false);
    setCurrentPage(1);
    setTotalRecords(0);
    setTotalPages(0);
    setTableData([]);
  };

  const handleSort = (dataField: string) => {
    if (!dataField) return;

    setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    setRefetchTable(true);
  };

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
    setRefetchTable(true);
  };

  if (generateClicked && refetchTable) {
    setRefetchTable(false);
    getMonitoringReportHandler();
  }

  if (downloadClicked && refetchTable) {
    setGenerateClickedBeforeDownload(true);
    setRefetchTable(false);
    downloadMonitoringReportHandler();
  }

  useEffect(() => {
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setRefetchTable(true);
  }, [records]);

  return {
    tableData,
    tableColumns,
    tableKey,
    navigate,

    contextState,
    contextDispatchHandler,
    getFilterOptionsLoading,

    sortColumn,
    handleSort,

    currentPage,
    totalPages,
    totalRecords,
    handlePageChange,

    generateMonitoringReport,
    downloadMonitoringReportCSV,
    dataGenerated,
    records,
    setRecords,

    setSelectedCheckboxItem,
    setRefetchTable,

    // Filters
    ruleStatus,
    setRuleStatus,

    reviewStatus,
    setReviewStatus,

    checkRule,
    setCheckRule,
    checkRuleOptions,

    ruleGroup,
    setRuleGroup,
    ruleGroupOptions,

    publisher,
    setPublisher,
    publisherOptions,

    urlSearch,
    setUrlSearch,

    clearFormHandler,
    handleOnSelectCheckbox,
    errorMessage,
    isLoading: getFintelChecksLoading,
  };
};
