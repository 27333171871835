import styled from 'styled-components';
import { colors, fonts } from '../../styles/theme';
import { hexToRGB, toRem } from '../../utils';

type ColumnProps = {
  readonly isDragging: boolean;
  readonly minWidth?: string;
  readonly isClickable?: boolean;
}

type SortButtonProps = {
  readonly isSorted: boolean;
}

type FixedHeaderSpanProps = {
  readonly width?: string;
}

type FixedHeaderProps = {
  readonly shown?: boolean;
}

export const HeaderButtonsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  margin-bottom: 1rem;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  color: ${colors.color13};
  padding: 0rem 0.5rem;
`;

export const DragButton = styled.div`
  height: 1rem;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: ${colors.color13};
  padding-left: 1rem;

  :hover {
    cursor: pointer;
  }
`;

export const HeaderDragButtonStyled = styled.div``;

export const HeaderResizeButtonStyled = styled.div`
  cursor: col-resize;
`;

export const HeaderResizeButtonStyledHidden = styled.div`
  visibility: hidden;
`;

export const BodyStyled = styled.div`
  position: relative;
  ${fonts.lato.H16}

  display: flex;
  overflow-x: auto;
  color: ${colors.color1};
`;

export const DropZoneStyled = styled.div`
  display: flex;
`;

export const ColumnStyled = styled.div<ColumnProps>`
  min-width: ${({ minWidth }) => minWidth || '5rem'};
  max-width: ${({ minWidth }) => minWidth || '5rem'};
  ${({ isDragging }) => {
    if (isDragging) {
      return `
        border: 1px solid ${colors.color2};
        background-color: ${colors.color4};
        
        ${HeaderButtonsStyled} {
          opacity: 1;
          pointer-events: all;
        }
        ${DragButton} {
          opacity: 1;
          pointer-events: all;
        }
      `;
    }
  }}

  &:hover {
    ${HeaderButtonsStyled} {
      opacity: 1;
      pointer-events: all;
    }
    ${DragButton} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const RowStyled = styled.div<ColumnProps>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ isDragging }) => {
    if (isDragging) {
      return `
        border-bottom: 1px solid transparent;
      `;
    }

    return `
      border-bottom: 1px solid ${colors.color15};
    `;
  }}

  &.table-row-hover {
    background-color: ${colors.color6};
  }
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

export const CellStyled = styled.div`
  padding: ${toRem(8)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 48px;
  display: flex;
  align-items: center;
`;

export const HeaderStyled = styled.div`
  ${fonts.gotham.H12M}
  text-transform: uppercase;
  padding: 0.5rem;
  color: ${colors.color16};
`;

export const SortButtonStyled = styled.button<SortButtonProps>`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  text-transform: uppercase;
  justify-content: flex-start;
  text-align: left;
  color: ${colors.color13};
  ${fonts.gotham.H12M}
  height: 2.5rem;
  padding-left: 0px;

  cursor: ${({ isSorted }) => (isSorted ? 'pointer' : 'normal')};

  svg {
    color: ${colors.color5};
    font-size: ${toRem(14)};
    margin-left: ${toRem(8)};
  }
`;

export const FooterStyled = styled(CellStyled)``;

export const LoadingStyled = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 1rem;
  width: 100%;
  background-color: rgba(${hexToRGB(colors.color3)}, 0.5);
`;

export const ContainerStyled = styled.div`
  position: relative;
`;

export const FixedHeaderStyled = styled.div<FixedHeaderProps>`
  ${fonts.lato.H12M}
  position: fixed;
  top: 0;
  z-index: 1;
  background: ${colors.color4};
  border-bottom: 1px solid ${colors.color19};
  display: ${({ shown }) => (shown ? 'flex' : 'none')};
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.5rem;
  height: 3rem;
  overflow-x: auto;
  text-transform: uppercase;
  scrollbar-width: none;
  line-height: 1.3;
  color: ${colors.color13};
  left: auto;
`;

export const FixedHeaderSpanStyled = styled.span<FixedHeaderSpanProps>`
  width: ${({ width }) => (width || 'fit-content')};
  min-width:  ${({ width }) => (width || '')};
`;
