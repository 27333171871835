export type TSummaryDashboardData = {
  checks: {
    total: number,
    urlsMonitored: number,
    urlsIncomplete: number,
  },
  passed: {
    total: number,
    new: number,
    brandNew: number,
    newlyUpdated: number,
    closed: number,
    internalReview: number,
    externalReview: number,
  },
  failed: {
    total: number,
    new: number,
    brandNew: number,
    newlyUpdated: number,
    closed: number,
    internalReview: number,
    externalReview: number,
  },
}

export const EmptySummaryDashboardData: TSummaryDashboardData = {
  checks: {
    total: 0,
    urlsMonitored: 0,
    urlsIncomplete: 0,
  },
  passed: {
    total: 0,
    new: 0,
    brandNew: 0,
    newlyUpdated: 0,
    closed: 0,
    internalReview: 0,
    externalReview: 0,
  },
  failed: {
    total: 0,
    new: 0,
    brandNew: 0,
    newlyUpdated: 0,
    closed: 0,
    internalReview: 0,
    externalReview: 0,
  },
};
