export const MODAL_SECTIONS = {
  CHECK_RESULTS: 'Report Check Results',
  TEXT_PHRASES: 'Text Phrases Checked',
  REVIEW_STATUS: 'Report Review Status',
};

export const MODAL_TITLE = 'Rule: ';

export const RULE_STATUS_SETTING_TITLE = 'Rule Status Setting:';
export const REPORT_RULE_STATUS = 'Report Rule Status:';
export const RULE_STATUS_TITLE = 'Rule Status';
export const CHECK_DATE_TITLE = 'Report Check Date:';
export const FEEDBACK_TITLE = 'Feedback';
export const RULE_TEXT_TITLE = 'Rule Text';
