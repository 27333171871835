import { gql } from '@apollo/client';

export const GET_MONITORING_REPORT_RESULTS = gql`
  query getMonitoringReport($input: MonitoringReportInput!) {
    monitoringReport(input: $input) {
      count
      totalPages
      currentPage
      monitoringReports {
        id
        reviewStatus
        previousReviewStatus
        reviewDate
        ruleStatus
        pageUrl
        ruleName
        ruleGroup
        publisher
        createDatetime
      }
    }
  }
`;

export type MonitoringReportType = {
  id: string,
  merchantId: string,
  pageUrl: string,
  urlSearch: string,
  ruleName: string,
  ruleGroup: string,
  ruleStatus: string,
  createDatetime: string,
  publisher: string,
  reviewStatus: string,
  reviewDate: string,
  previousReviewStatus: string,
  checked: boolean,
}
