import { gql } from '@apollo/client';

export const GET_MONITORING_REPORT_FILTER_OPTIONS = gql`
  query getMonitoringReportFilters($input: MonitoringReportFiltersInput) {
    getMonitoringReportFilters(input: $input) {
      rules {
        label
        value
      }
      ruleGroups {
        label
        value
      }
      publishers {
        label
        value
      }
    }
  }
`;
