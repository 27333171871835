import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck, faCircleXmark, faChartPie, faRotateRight, faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import { colors } from '../../../../../styles/theme';
import { toRem } from '../../../../../utils';
import { camelCaseToSentenceCase } from '../../FintelCheckChangeHistory/contracts';
import useRuleSummaryDashboard from './hooks/useRuleSummaryDashboard';
import { RULE_SUMMARY_DASHBOARD } from './enums';
import { LoadingDots } from '../../../../../components';
import { ConfirmationModal } from '../../../../../components/Modal/ConfirmationModal';

const RulesSummaryDashboard = () => {
  const hook = useRuleSummaryDashboard();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false);

  /* Calculates the total amount of passed checks with the exception of Closed and Total */
  const extractTotalPassedAmount = () => {
    if (!hook.dashboardData) return 0;
    return hook.dashboardData.passed.brandNew
      + hook.dashboardData.passed.new
      + hook.dashboardData.passed.externalReview
      + hook.dashboardData.passed.internalReview
      + hook.dashboardData.passed.newlyUpdated;
  };

  const renderChecksContent = () => (
    <Styled.BoxStyled
      background={colors.color39}
    >
      {hook.getDashboardDataLoading ? (
        <Styled.LoadingDotsStyled>
          <LoadingDots isWhite />
        </Styled.LoadingDotsStyled>
      ) : (
        <>
          <Styled.BoxTitleWrapperStyled>
            <FontAwesomeIcon
              icon={faChartPie}
              style={{ marginRight: toRem(12), fontSize: toRem(28) }}
            />
            <Styled.BoxTitleStyled>
              {hook.dashboardData.checks.total ?? 0}
              {' '}
              {RULE_SUMMARY_DASHBOARD.CHECKS}
            </Styled.BoxTitleStyled>
          </Styled.BoxTitleWrapperStyled>

          <Styled.BoxContentWrapperStyled>
            <Styled.BoxContentStyled>
              {hook.dashboardData.checks.urlsMonitored}
              {' '}
              {RULE_SUMMARY_DASHBOARD.URLS_MONITORED}
            </Styled.BoxContentStyled>
          </Styled.BoxContentWrapperStyled>
        </>
      )}
    </Styled.BoxStyled>
  );

  const renderPassedContent = () => (
    <Styled.BoxStyled background={colors.color21}>
      {hook.getDashboardDataLoading ? (
        <Styled.LoadingDotsStyled>
          <LoadingDots isWhite />
        </Styled.LoadingDotsStyled>
      ) : (
        <>
          <Styled.BoxTitleWrapperStyled>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ marginRight: toRem(12), fontSize: toRem(28) }}
            />
            <Styled.BoxTitleStyled>
              {hook.dashboardData.passed.total}
              %
              {' '}
              {RULE_SUMMARY_DASHBOARD.PASSED}

            </Styled.BoxTitleStyled>
            <Styled.CloseChecksButtonStyled
              onClick={() => setIsConfirmationModalOpen(true)}
              disabled={hook.getDashboardDataLoading || hook.setChecksToClosedLoading}
              theme="secondary"
              type="button"
              loading={hook.setChecksToClosedLoading}
            >
              {RULE_SUMMARY_DASHBOARD.SET_CLOSE_CHECKS}
            </Styled.CloseChecksButtonStyled>
          </Styled.BoxTitleWrapperStyled>

          <Styled.BoxContentWrapperStyled>
            {Object.entries(hook.dashboardData.passed).map((key, index) => {
              if (key[0] === 'total' || key[1] === 0) return null;
              return (
                <Styled.BoxContentStyled key={`${key[0]}-${index}`}>
                  {key[1]}
                  {' '}
                  {camelCaseToSentenceCase(key[0])}
                </Styled.BoxContentStyled>
              );
            })}
          </Styled.BoxContentWrapperStyled>
        </>
      )}
    </Styled.BoxStyled>
  );

  const renderFailedContent = () => (
    <Styled.BoxStyled background={colors.color14}>
      {hook.getDashboardDataLoading ? (
        <Styled.LoadingDotsStyled>
          <LoadingDots isWhite />
        </Styled.LoadingDotsStyled>
      ) : (
        <>
          <Styled.BoxTitleWrapperStyled>
            <FontAwesomeIcon
              icon={faCircleXmark}
              style={{ marginRight: toRem(12), fontSize: toRem(28) }}
            />
            <Styled.BoxTitleStyled>
              {hook.dashboardData.failed.total}
              %
              {' '}
              {RULE_SUMMARY_DASHBOARD.FAILED}
            </Styled.BoxTitleStyled>
          </Styled.BoxTitleWrapperStyled>

          <Styled.BoxContentWrapperStyled>
            {Object.entries(hook.dashboardData.failed).map((key, index) => {
              if (key[0] === 'total' || key[1] === 0) return null;
              return (
                <Styled.BoxContentStyled key={`${key[0]}-${index}`}>
                  {key[1]}
                  {' '}
                  {camelCaseToSentenceCase(key[0])}
                </Styled.BoxContentStyled>
              );
            })}
          </Styled.BoxContentWrapperStyled>
        </>
      )}
    </Styled.BoxStyled>
  );

  const renderIncompleteContent = () => (
    <Styled.BoxStyled background={colors.color12}>
      {hook.getDashboardDataLoading ? (
        <Styled.LoadingDotsStyled>
          <LoadingDots isWhite />
        </Styled.LoadingDotsStyled>
      ) : (
        <Styled.BoxTitleWrapperStyled>
          <FontAwesomeIcon
            icon={faCircleExclamation}
            style={{ marginRight: toRem(12), fontSize: toRem(28) }}
          />
          <Styled.BoxTitleStyled>
            {hook.dashboardData.checks.urlsIncomplete}
            %
            {' '}
            {RULE_SUMMARY_DASHBOARD.INCOMPLETE}
          </Styled.BoxTitleStyled>
        </Styled.BoxTitleWrapperStyled>
      )}
    </Styled.BoxStyled>
  );

  if (!hook.dashboardData) return null;

  return (
    <Styled.WrapperStyled>
      <Styled.RefreshButtonStyled
        isLoading={hook.getDashboardDataLoading}
        onClick={() => hook.fetchDashboardData()}
        disabled={hook.getDashboardDataLoading}
        theme="text-only"
        type="button"
      >
        {hook.getDashboardDataLoading ? (
          <Styled.RefreshIconStyled
            icon={faRotateRight}
            style={{
              transition: 'transform 0.3s ease',
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faRotateRight}
            style={{
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
              fontSize: toRem(20),
            }}
          />
        )}
      </Styled.RefreshButtonStyled>

      <Styled.ContentStyled>
        {/* ----------- Checks Dashboard ----------- */}
        {renderChecksContent()}
        {/* ----------- Passed Dashboard ----------- */}
        {renderPassedContent()}
        {/* ----------- Failed Dashboard ----------- */}
        {renderFailedContent()}
        {/* ----------- Incomplete Dashboard ----------- */}
        {renderIncompleteContent()}
      </Styled.ContentStyled>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        title={RULE_SUMMARY_DASHBOARD.CONFIRMATION_MODAL.TITLE}
        desc={RULE_SUMMARY_DASHBOARD.CONFIRMATION_MODAL.DESCRIPTION_START
          + extractTotalPassedAmount()
          + RULE_SUMMARY_DASHBOARD.CONFIRMATION_MODAL.DESCRIPTION_END}
        onConfirm={async () => {
          await hook.closeAllChecks();
          setIsConfirmationModalOpen(false);
        }}
        onClose={() => setIsConfirmationModalOpen(false)}
        onCloseText={RULE_SUMMARY_DASHBOARD.CONFIRMATION_MODAL.CANCEL}
        onConfirmText={RULE_SUMMARY_DASHBOARD.CONFIRMATION_MODAL.CONFIRM}
        width="40rem"
      />

    </Styled.WrapperStyled>
  );
};

export default RulesSummaryDashboard;
