import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from '../../../../../styles/theme';
import { Button } from '../../../../../components';
import { toRem } from '../../../../../utils';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
  color: ${colors.color4};
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
`;

export const BoxStyled = styled.div<{background: string}>`
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: ${({ background }) => (background || colors.color2)};
  width: 100%;
`;

export const BoxTitleWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 2.75rem;
`;

export const BoxTitleStyled = styled.p`
  ${fonts.lato.H22M};
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
`;

export const BoxContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  gap: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const BoxContentStyled = styled.p`
  ${fonts.lato.H16};
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const RefreshButtonStyled = styled(Button)<{isLoading: boolean}>`
  align-self: flex-end;
  padding-left: 0;
  padding-right: 0;
  color: ${colors.color1};

  &:hover {
    color: ${colors.color2};
  }
`;

export const LoadingDotsStyled = styled.div``;

export const CloseChecksButtonStyled = styled(Button)`
  margin-left: 2rem;
  align-self: flex-end;
  border-color: ${colors.color4};
  color: ${colors.color4};

  &:hover {
    background-color: ${colors.color4};
    color: ${colors.color21};
    border-color: ${colors.color21};
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const RefreshIconStyled = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: ${toRem(20)};
  animation: ${rotate} 0.8s backwards infinite;
`;
