import { gql } from '@apollo/client';

export const GET_MONITORING_RULE_STATUS_DATA = gql`
  query getMonitoringRuleStatusData($input: MonitoringRuleStatusInput!) {
    getMonitoringRuleStatusData(input: $input) {
    ruleStatusSettings {
      fieldsRequired
      fieldState
      ruleState
    }
      textPhrases {
        textPhrase
        status
        hasFeedback
      }
    }
  }
`;

export type TextPhraseType = {
  status: string,
  textPhrase: string,
  hasFeedback: boolean
}
