import { useLazyQuery, useMutation } from '@apollo/client';
import { useState, useEffect } from 'react';
import { GET_SUMMARY_DASHBOARD_DATA } from '../graphql/getSummaryDashboardData';
import { useToast, useUserInfo } from '../../../../../../hooks';
import { EmptySummaryDashboardData, TSummaryDashboardData } from '../types';
import { UPDATE_PASSED_CHECKS_TO_CLOSED } from '../graphql/updatePassedChecksToClosed';
import { merchant } from '../../../../../../components/SideMenu/utils';

const useRuleSummaryDashboard = () => {
  const [dashboardData, setDashboardData] = useState<TSummaryDashboardData>(EmptySummaryDashboardData);
  const { hookWhoAmI } = useUserInfo();
  const toast = useToast();
  const [getDashboardData, { loading: getDashboardDataLoading }] = useLazyQuery(GET_SUMMARY_DASHBOARD_DATA);
  const [setChecksToClosed, { loading: setChecksToClosedLoading }] = useMutation(UPDATE_PASSED_CHECKS_TO_CLOSED);

  /* Loads Dashboard data and breakdown between Checks/Passed/Failed */
  const fetchDashboardData = async () => {
    const { data, error } = await getDashboardData({
      variables: {
        input: {
          merchantId: hookWhoAmI.companyId,
        },
      },
      fetchPolicy: 'no-cache',
    });
    if (error) {
      toast.hookShowToast(error.message);
      return;
    }
    if (data) {
      setDashboardData(data.getSummaryDashboardData);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  /* This function sets all checks with state Passed to closed */
  const closeAllChecks = async () => {
    const { data, errors } = await setChecksToClosed({
      variables: {
        input: {
          userId: hookWhoAmI.id,
          merchantId: hookWhoAmI.companyId,
        },
      },
    });
    if (errors && errors?.length > 0) {
      toast.hookShowToast(errors[0].message);
    }
    if (data && data.updatePassedChecksToClosed) {
      await fetchDashboardData();
    }
  };

  return {
    dashboardData, fetchDashboardData, getDashboardDataLoading, closeAllChecks, setChecksToClosedLoading,
  };
};

export default useRuleSummaryDashboard;
