import React from 'react';
import { faArrowDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  AppWrapper, ErrorBanner, Modal, PageTitle,
} from '../../../../components';
import { path } from '../../../../utils';
import { useMonitoringRulesReport } from './hooks/useMonitoringRulesReport';
import { MonitoringSummaryReport } from '../Components/MonitoringSummaryReport/index';
import { MonitoringHistoryReport } from '../Components/MonitoringHistoryReport/index';
import { useSummaryReport } from '../Components/MonitoringSummaryReport/hooks/useSummaryReport';
import { useHistoryReport } from '../Components/MonitoringHistoryReport/hooks/useHistoryReport';
import { DefaultPropsType } from '../../../../types';
import { Permission } from '../../../../entities';

import * as Styled from './styles';
import RulesSummaryDashboard from '../Components/RulesSummaryDashboard';
import MonitoringDetailsModal from '../Components/MonitoringDetailsModal';
import { SUMMARY } from '../Components/MonitoringSummaryReport/enums';

type FintelCheckReportProps = DefaultPropsType;

const MonitoringRulesReport = ({ permissionsCodeList = [] }: FintelCheckReportProps) => {
  const hook = useMonitoringRulesReport();

  const [isReadOnly] = Permission.readOnlyPermissionsList(permissionsCodeList);

  return (
    <AppWrapper
      background
      permissionsCodeList={permissionsCodeList}
    >
      <ErrorBanner
        message={hook.errorMessage}
        isOpen={!!hook.errorMessage}
      />
      <Styled.HeaderStyled>
        <PageTitle>{hook.displaySummary ? path.monitoringRulesReport.name : path.monitoringRulesHistoryReport.name}</PageTitle>
      </Styled.HeaderStyled>
      <Styled.SectionWrapper>

        {(hook.displaySummary) && (
          <>
            <RulesSummaryDashboard />

            <MonitoringSummaryReport
              summaryHook={hook.summaryHook}
              hook={hook}
            />
          </>
        )}
        {(!hook.displaySummary) && (
        <MonitoringHistoryReport
          historyHook={hook.historyHook}
          hook={hook}
        />
        )}
        <MonitoringDetailsModal hook={hook} />

        <Modal
          isOpen={hook.isScreenshotModalOpen}
          closeModalFunction={() => {
            hook.closeScreenshotModal();
          }}
        >
          { hook.getScreenshotImageLoading ? (
            <Styled.LoadingDotsStyled>
              <div> Loading...</div>
            </Styled.LoadingDotsStyled>
          ) : (
            <>
              <Styled.ScreenshotModalHeaderContainer>
                <Styled.ScreenshotModalHeaderItemLeft>Screenshot Image</Styled.ScreenshotModalHeaderItemLeft>
                <Styled.ScreenshotModalHeaderItemCenter>
                  <Styled.SimpleFlexWrapper>
                    <Styled.ButtonStyled
                      theme="tertiary"
                      onClick={() => hook.downloadScreenshot()}
                      width="160px"
                      disabled={hook.getScreenshotImageLoading}
                    >
                      <Styled.IconStyled icon={faArrowDown} />
                      {SUMMARY.DOWNLOAD_IMAGE}
                    </Styled.ButtonStyled>
                  </Styled.SimpleFlexWrapper>
                </Styled.ScreenshotModalHeaderItemCenter>
                <Styled.ScreenshotModalHeaderItemRight>
                  <Styled.DetailedViewCloseIcon
                    icon={faTimes}
                    onClick={() => hook.closeScreenshotModal()}
                  />
                </Styled.ScreenshotModalHeaderItemRight>
              </Styled.ScreenshotModalHeaderContainer>
              <Styled.ScreenshotImage>
                { hook.screenshotImage ? (
                  <img
                    src={`data:image/jpeg;base64,${hook.screenshotImage}`}
                    alt="cover"
                  />
                ) : (
                  <Styled.NoImage> No Image Found </Styled.NoImage>
                )}
              </Styled.ScreenshotImage>
            </>
          )}

        </Modal>
      </Styled.SectionWrapper>
    </AppWrapper>
  );
};

export default MonitoringRulesReport;
