export const CANCEL_ADD_RULE = {
  TITLE: 'Leave Rule?',
  WARNING: 'You have not finished adding your rule yet. Do you want to leave without finishing?',
  CANCEL_BUTTON: 'Cancel',
  LEAVE_BUTTON: 'Leave',
};

export const STEP_ONE_MODAL = {
  TITLE: 'Add Evaluation Rule',
  RULE_NAME: {
    LABEL: 'Rule Name',
    TOOLTIP: 'Enter a rule name that describes the type of checks that the Fintel Check tool will perform. The clearer the description, the easier you will be able to search and find the rule.',
    PLACEHOLDER: 'Name this rule',
  },
  DESCRIPTION: 'Select the product(s) for which the rule will apply',
  PRODUCT_CATEGORY: {
    LABEL: 'Product Category',
    TOOLTIP: 'Select a type of product for which the rule will apply. Product categories can be maintained and updated in the Product Catalog in your Account Settings.',
    PLACEHOLDER: 'Select a category',
  },
  PRODUCT: {
    LABEL: 'Product(s)',
    TOOLTIP: 'Select a specific prodduct for which the rule will apply. Products can be added, maintained, and updated in the Product Catalog in your Account Settings.',
    PLACEHOLDER: 'Select a category',
  },
  STEP_COUNTER: 'Step 1 of 3',
  CANCEL_BUTTON: 'Cancel',
  CONTINUE_BUTTON: 'Continue',
};

export const STEP_TWO_MODAL = {
  TITLE: 'Define Rule Conditions',
  DESCRIPTION: 'Select the minimum conditions for the rule to be applicable',
  GUIDE: 'From the dropdowns below, specify the conditions for the rule to be applicable.',
  CRITERIA: {
    LABEL: 'Criteria',
    TOOLTIP: "Define the type of characteristics that determine the rule's applicability.",
    PLACEHOLDER: 'Select Criteria',
  },
  SELECT_FIELD: {
    LABEL: 'Select Field',
    TOOLTIP: "Provide the specific characteristics that determine the rule's applicability.",
    PLACEHOLDER: 'Select a Product Feed field',
  },
  PROVIDE_FIELD: {
    LABEL: 'Provide Field',
    TOOLTIP: "Provide the specific characteristics that determine the rule's applicability.",
  },
  STEP_COUNTER: 'Step 2 of 3',
  CANCEL_BUTTON: 'Cancel',
  CONTINUE_BUTTON: 'Continue',
};

export const STEP_THREE_MODAL = {
  TITLE: 'Add Rule Requirement',
  DESCRIPTION: 'Define the parameters for the rule to check',
  GUIDE: 'Add one or more requirements for the rule to check',
  AND: 'And',
  RULE_REQUIREMENT: {
    LABEL: 'Rule Requirement',
    TOOLTIP: 'Select the information related to your product that Fintel Check will scan and verify. The tool will look for this information to verify and check against the defined rule.',
    PLACEHOLDER: 'Select Product Feed Info',
  },
  PRODUCT_FEED_DATA: {
    LABEL: 'Product Feed Data',
    TOOLTIP: 'For each rule requirement applied to a product, the rule will call the specific data point from your Product Catalog. To edit product information, please visit your Account tab and navigate to your Product Catalog.',
    PLACEHOLDER: {
      NO_PRODUCTS: 'Please enter the value to be checked',
      DEFAULT: 'Data associated with product feed',
    },
    BUTTON: 'Click to view all product feed data',
  },
  REQUIRED: {
    LABEL: 'Required',
    TOOLTIP: 'Mark data as "required" if it must appear on your Publisher\'s pages where a tracking link also exists',
  },
  ADD_RULE_REQUIREMENT: 'Add rule requirement',
  STEP_COUNTER: 'Step 3 of 3',
  CANCEL_BUTTON: 'Cancel',
  CONTINUE_BUTTON: 'Continue',
};

export const SUCCESS_MESSAGES = {
  CREATED_RULE_TOAST: 'Rule successfully created.',
};

export const ERROR_MESSAGES = {
  MISSING_PRODUCT_FEED: 'Please select a Product Feed',
  MISSING_CURRENCY: 'Please enter a valid dollar amount (e.g. $100)',
  MISSING_PERCENTAGE: 'Please enter a valid percentage (e.g. 4.5%)',
  MISSING_VALUE: 'Please enter a value',
  RULE_NAME_TAKEN: 'Please select a unique rule name.',
};
