import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { colors, fonts } from '../../../../../styles/theme';
import { Modal, Tooltip, Radio } from '../../../../../components';

type TooltipProps = {
  readonly feedback: boolean
}

export const DetailedViewModal = styled(Modal)`
  width: 100%;
  &>div {
    width: 75rem;
    max-width: 75rem;
  };
`;

export const DetailedViewContent = styled.div`
  padding: 1rem;
`;

export const DetailedViewHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DetailedViewTitle = styled.p`
  ${fonts.gotham.H24M};
  color: ${colors.color1};
`;

export const DetailedViewUrlButtonWrapper = styled.div`
  padding-top: 1rem;
`;

export const DetailedViewUrlButton = styled.a`
  color: ${colors.color2};
  ${fonts.lato.H16};
  &:hover {
    color: ${colors.color1};
  }
`;

export const DetailedViewSectionTitle = styled.p`
  ${fonts.lato.H16B}
  color: ${colors.color1};
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const DetailedViewCloseIcon = styled(FontAwesomeIcon)`
  display: flex;
  align-self: flex-start;
  cursor: pointer;
  font-size: 1.125rem;
  color: ${colors.color1};
  &:hover {
    color: ${colors.color2};
  }
`;

export const ReviewStatusWrapper = styled.div`
  margin-top: 1rem;
`;

export const CheckResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.313rem;
`;

export const CheckResultsRow = styled.div`
  display: flex;
  margin-bottom: 0.125;
`;

export const CheckResultsTextLabel = styled.div`
  margin-right: 0.3rem;
`;

export const CheckResultsTextValue = styled.div`
`;

export const CheckResultsTextValueGreen = styled.div`
  color: ${colors.color29}
`;

export const CheckResultsTextValueRed = styled.div`
  color: ${colors.color31}
`;

export const TextPhrasesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.313rem;
`;

export const TextPhrasesColumnWrapper = styled.div`
  display: flex;
  padding: 0.625rem;
  border-bottom: 0.063rem solid ${colors.color19};
  color: ${colors.color13};
  ${fonts.gotham.H11M};
  font-weight: bold;
  text-transform: uppercase;
`;

export const TextPhrasesColumn1 = styled.div`
 flex: 1 0 0;
`;

export const TextPhrasesColumn2 = styled.div`
 flex: 10 0 0;
 margin-left: 1rem;
`;

export const TextPhrasesValue1 = styled.div`
 flex: 1 0 0;
`;

export const TextPhrasesValue2 = styled.div`
 flex: 10 0 0;
 margin-left: 1rem;
 display: flex;
 text-wrap: wrap !important;
 word-wrap: break-word !important;
 white-space: pre-wrap !important;
 word-break: break-all !important;
`;

export const TextPhrasesValuesWrapper = styled.div`
 display: flex;
 padding: 0.625rem;
 border-bottom: 0.063 solid ${colors.color19};
`;

export const TooltipStyled = styled(Tooltip)<TooltipProps>`
  div {
    color: ${colors.color4};
    
    svg {
      color: ${colors.color1};
    }
  }
  div:last-child {
    left: ${({ feedback }) => (feedback ? '380%' : '300%')};
    :after {
      left: 1rem;
    }
  }
  margin: 0;
`;

export const FeedbackButtonWrapper = styled.div`
  display: flex;
  flex: 1 0 0;
  align-self: flex-start;
  &>button {
    padding: 0;
  }
`;

export const FeedbackModalContent = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

export const FeedbackModalTitle = styled.p`
  ${fonts.gotham.H18B};
  color: ${colors.color1};
  margin-bottom: 1rem;
`;
export const FeedbackModalText = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
  margin-bottom: 1rem;
`;

export const FeedbackModalRadioWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const FeedbackModalRadio = styled(Radio)`
  width: max-content;
`;

export const SubmitFeedbackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  gap: 1rem;
`;
