import styled from 'styled-components';
import { toRem } from '../../../../../utils';
import { Button, Select } from '../../../../../components';
import { fonts, colors } from '../../../../../styles/theme';

type CellWrapperStyledProps = {
  readonly theme?: string
}

export const FAButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  padding: 0;
  font-size: 1.2rem;
  width: 30px;
  color: ${colors.color1};
`;

export const CellWrapperStyled = styled.div<CellWrapperStyledProps>`
  height: 100%;
  min-height: ${({ theme }) => (theme === 'checkBox' ? 'auto' : toRem(38))};
  display: flex;
  align-items: ${({ theme }) => (theme === 'checkBox' ? 'center' : 'flex-start')};
  justify-content: ${({ theme }) => (theme === 'checkBox' ? 'center' : 'space-between')};
  flex-direction: ${({ theme }) => (theme === 'checkBox' ? 'row' : 'column')};
`;

export const ErrorColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 38px;
`;

export const ErrorSpanWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContentButtonWrapper = styled.div`
  & button {
    padding: 0;
  }
  display: flex;
`;

export const TableDataStyled = styled.div`
  display: flex;
  width: 100%;
  min-height: 48px;
  line-height: ${toRem(24)};
  align-items: center;
  white-space: normal;
  justify-content: flex-start;
  flex-direction: row;

  &:hover {
    color: ${colors.color2};
  }

  em {
    font-style: italic;
  }
}`;
