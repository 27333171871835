import React, { useEffect } from 'react';
import { faFlag as checked, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFlag as unchecked } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Styled from './styles';
import {
  MODAL_SECTIONS, MODAL_TITLE, RULE_STATUS_SETTING_TITLE, RULE_STATUS_TITLE, CHECK_DATE_TITLE, FEEDBACK_TITLE, RULE_TEXT_TITLE, REPORT_RULE_STATUS,
} from './enums';

import ReviewStatus from '../../ChangeMonitoring/components/ReviewStatus';
import {
  Button, Pagination, Table, Modal,
} from '../../../../../components';
import { columns } from '../../ChangeMonitoring/ChangeMonitoringDetails/contracts/columns';
import { FEEDBACK_MODAL, BULK_RULE_REVIEW_STATUSES as RULE_REVIEW_STATUSES } from '../MonitoringSummaryReport/enums';
import { TextPhraseType } from './graphql/getMonitoringRuleStatusData';
import { colors } from '../../../../../styles/theme';
import { useHistoryReport } from '../MonitoringHistoryReport/hooks/useHistoryReport';
import { useMonitoringRulesReport } from '../../MonitoringRulesReport/hooks/useMonitoringRulesReport';

type MonitoringDetailsModalProps = {
  hook: ReturnType<typeof useMonitoringRulesReport>
}

const MonitoringDetailsModal = ({ hook }: MonitoringDetailsModalProps) => {
  useEffect(() => {
    if (hook.isDetailedViewModalOpen) {
      hook.getReviewStatusData();
      hook.getTextPhrasesData();
    }
  }, [hook.isDetailedViewModalOpen]);

  function ruleStatusColor(ruleStatus: string) {
    if (!ruleStatus) return;

    switch (ruleStatus) {
      case 'Pass':
        return (
          <Styled.CheckResultsTextValueGreen>
            { ruleStatus }
          </Styled.CheckResultsTextValueGreen>
        );
      case 'Fail':
        return (
          <Styled.CheckResultsTextValueRed>
            { ruleStatus }
          </Styled.CheckResultsTextValueRed>
        );
      default:
        return (
          <Styled.CheckResultsTextValue>
            { ruleStatus }
          </Styled.CheckResultsTextValue>
        );
    }
  }

  return (
    <>
      <Styled.DetailedViewModal
        isOpen={hook.isDetailedViewModalOpen}
        closeModalFunction={() => hook.isDetailedViewModalOpen && !hook.isFeedbackModalOpen && hook.closeDetailedViewModal()}
      >
        <Styled.DetailedViewContent>
          <Styled.DetailedViewHeader>
            <>
              <Styled.DetailedViewTitle>
                {MODAL_TITLE}
                {' '}
                {hook.detailedViewModalInfo?.ruleName}
              </Styled.DetailedViewTitle>
              <Styled.DetailedViewCloseIcon
                icon={faTimes}
                onClick={() => hook.closeDetailedViewModal()}
              />
            </>
          </Styled.DetailedViewHeader>
          <Styled.DetailedViewUrlButtonWrapper>
            <Styled.DetailedViewUrlButton
              href={hook.detailedViewModalInfo?.urlSearch}
              target="_blank"
              rel="noreferrer"
            >
              {hook.detailedViewModalInfo?.urlSearch}
            </Styled.DetailedViewUrlButton>
          </Styled.DetailedViewUrlButtonWrapper>

          {/* Check Results Report */}
          <Styled.ReviewStatusWrapper>
            <Styled.DetailedViewSectionTitle>
              {MODAL_SECTIONS.CHECK_RESULTS}
            </Styled.DetailedViewSectionTitle>
            <Styled.CheckResultsWrapper>
              <Styled.CheckResultsRow>
                <Styled.CheckResultsTextLabel>
                  {RULE_STATUS_SETTING_TITLE}
                </Styled.CheckResultsTextLabel>
                <Styled.CheckResultsTextValue>
                  {hook.ruleSettingsData}
                </Styled.CheckResultsTextValue>
              </Styled.CheckResultsRow>
              <Styled.CheckResultsRow>
                <Styled.CheckResultsTextLabel>
                  {REPORT_RULE_STATUS}
                </Styled.CheckResultsTextLabel>
                {hook.detailedViewModalInfo?.ruleStatus && (ruleStatusColor(hook.detailedViewModalInfo?.ruleStatus))}
              </Styled.CheckResultsRow>
              <Styled.CheckResultsRow>
                <Styled.CheckResultsTextLabel>
                  {CHECK_DATE_TITLE}
                </Styled.CheckResultsTextLabel>
                <Styled.CheckResultsTextValue>
                  {hook.detailedViewModalInfo?.createDatetime}
                </Styled.CheckResultsTextValue>
              </Styled.CheckResultsRow>
            </Styled.CheckResultsWrapper>
          </Styled.ReviewStatusWrapper>

          {/* Text Phrases Checked */}
          <Styled.ReviewStatusWrapper>
            <Styled.DetailedViewSectionTitle>
              {MODAL_SECTIONS.TEXT_PHRASES}
            </Styled.DetailedViewSectionTitle>
            <Styled.TextPhrasesWrapper>
              <Styled.TextPhrasesColumnWrapper>
                <Styled.TextPhrasesColumn1>
                  {FEEDBACK_TITLE}
                </Styled.TextPhrasesColumn1>
                <Styled.TextPhrasesColumn1>
                  {RULE_STATUS_TITLE}
                </Styled.TextPhrasesColumn1>
                <Styled.TextPhrasesColumn2>
                  {RULE_TEXT_TITLE}
                </Styled.TextPhrasesColumn2>
              </Styled.TextPhrasesColumnWrapper>
              <div>
                {hook.textPhrasesData && (
                  hook.textPhrasesData.map((data: TextPhraseType, index: number) => (
                    <Styled.TextPhrasesValuesWrapper key={index}>
                      <Styled.FeedbackButtonWrapper>
                        <Button
                          onClick={() => hook.handleOnFeedbackModalOpen(data)}
                          type="reset"
                          theme="text-only"
                        >
                          <FontAwesomeIcon
                            icon={data.hasFeedback ? checked : unchecked}
                            color={colors.color1}
                          />
                        </Button>
                      </Styled.FeedbackButtonWrapper>
                      <Styled.TextPhrasesValue1>
                        {data.status}
                      </Styled.TextPhrasesValue1>
                      <Styled.TextPhrasesValue2>
                        {data.textPhrase}
                      </Styled.TextPhrasesValue2>
                    </Styled.TextPhrasesValuesWrapper>
                  ))
                )}
              </div>
            </Styled.TextPhrasesWrapper>
          </Styled.ReviewStatusWrapper>

          {/* Review Status Report */}
          <Styled.ReviewStatusWrapper>
            {hook.reviewStatusTableData && hook.reviewStatusTableData.length > 0 && (
            <>
              <Styled.DetailedViewSectionTitle>
                {MODAL_SECTIONS.REVIEW_STATUS}
              </Styled.DetailedViewSectionTitle>
              <Table
                columns={columns()}
                data={hook.reviewStatusTableData || []}
                isLoading={hook.getMonitoringReviewStatusDataLoading}
                loadWithData={hook.reviewStatusTableData ? hook.reviewStatusTableData.length > 0 : false}
              />
              <Pagination
                currentPage={hook.currentReviewStatusPage}
                total={hook.totalReviewStatusPages}
                onPageChange={hook.handleOnChangeCurrentPage}
              />
            </>
            )}
            <ReviewStatus
              selectedReviewStatus={hook.selectedReviewStatus}
              setReviewStatusNote={hook.setReviewStatusNote}
              reviewStatusNote={hook.reviewStatusNote}
              shouldDisableSetReviewStatusButton={hook.shouldDisableSetReviewStatusButton}
              setReviewStatusLoading={hook.setReviewStatusLoading}
              setSelectedReviewStatus={hook.setSelectedReviewStatus}
              dropdownOptions={RULE_REVIEW_STATUSES}
              onClickAction={() => hook.handleOnSetReviewStatus(hook.selectedReviewStatus?.value || '', hook.detailedViewModalInfo?.id)}
            />
          </Styled.ReviewStatusWrapper>
        </Styled.DetailedViewContent>
      </Styled.DetailedViewModal>

      <Modal
        isOpen={hook.isFeedbackModalOpen}
        closeModalFunction={() => {
          hook.resetFeedbackModalValues();
        }}
      >
        <Styled.FeedbackModalContent>
          <Styled.FeedbackModalTitle>
            {FEEDBACK_MODAL.TITLE}
          </Styled.FeedbackModalTitle>

          <Styled.FeedbackModalText>
            {FEEDBACK_MODAL.DESCRIPTION}
          </Styled.FeedbackModalText>

          <Styled.FeedbackModalRadioWrapper>
            <Styled.FeedbackModalRadio
              name={FEEDBACK_MODAL.YES}
              value={FEEDBACK_MODAL.YES}
              label={FEEDBACK_MODAL.YES}
              checked={hook.isFeedbackRuleCorrect}
              onChange={() => hook.setIsFeedbackRuleCorrect(true)}
            />
            <Styled.FeedbackModalRadio
              name={FEEDBACK_MODAL.NO}
              value={FEEDBACK_MODAL.NO}
              label={FEEDBACK_MODAL.NO}
              checked={hook.isFeedbackRuleCorrect === false}
              onChange={() => hook.setIsFeedbackRuleCorrect(false)}
            />
          </Styled.FeedbackModalRadioWrapper>
          <Styled.SubmitFeedbackButtonWrapper>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                hook.resetFeedbackModalValues();
              }}
              theme="secondary"
              width="5rem"
              disabled={hook.setRuleFeedbackLoading}
            >
              {FEEDBACK_MODAL.CANCEL}
            </Button>
            <Button
              onClick={() => hook.handleFeedbackSubmit()}
              theme="primary"
              width="5rem"
              disabled={hook.isFeedbackRuleCorrect === undefined}
              loading={hook.setRuleFeedbackLoading}
            >
              {FEEDBACK_MODAL.SUBMIT}
            </Button>
          </Styled.SubmitFeedbackButtonWrapper>
        </Styled.FeedbackModalContent>
      </Modal>
    </>
  );
};

export default MonitoringDetailsModal;
