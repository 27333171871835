import React from 'react';
import {
  faEdit, faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormatedCell } from '../components';
import { CheckRulesOutputType, Rule } from '../types';
import { dateFormatter } from '../../../../../utils';
import * as Styled from './styles';

enum EProducts {
  ALL_PRODUCTS = 'All Products',
  NO_SPECIFIC_PRODUCTS = 'No Specific Products',
}

const formatProductNameColumn = (row: CheckRulesOutputType): string => {
  if (row.applicableProducts === EProducts.ALL_PRODUCTS) {
    return EProducts.ALL_PRODUCTS;
  }
  if (row.applicableProducts === EProducts.NO_SPECIFIC_PRODUCTS || !row.applicableProducts) {
    return EProducts.NO_SPECIFIC_PRODUCTS;
  }
  if (row.applicableProducts) {
    return row.applicableProducts;
  }
  return `${row.customizedProductId} - ${row.productName}`;
};

const formatCustomTextColumn = (row: CheckRulesOutputType): string => {
  let customText = '';
  if (row.fieldChecks && row.fieldChecks.length) {
    customText = row.fieldChecks.map((fc) => fc.expectedValue).join(', ');
    if (customText.length > 50) {
      customText = customText.substring(0, 50);
      customText += '...';
    }
  } else {
    customText = 'No Custom Text';
  }
  return customText;
};

export const columns = (isReadOnly: boolean, handleDelete: (selected: Rule) => void, handleEdit: (selected: Rule) => void): TableColumn[] => [
  {
    dataField: '',
    text: 'ACTIONS',
    formatter: (val: any, row: any) => (
      <Styled.TableDataStyled>
        {isReadOnly && (
          <span>&nbsp;</span>
        )}

        <Styled.FAButtonStyled
          theme="text-only"
          onClick={() => handleEdit(row)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Styled.FAButtonStyled>

        {!isReadOnly && (
          <Styled.FAButtonStyled
            theme="text-only"
            onClick={() => handleDelete(row)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Styled.FAButtonStyled>
        )}
      </Styled.TableDataStyled>
    ),
    width: '7%',
  },
  {
    dataField: 'ruleName',
    text: 'Rule Name',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <FormatedCell
        value={value}
      />
    ),
    width: '15%',
  },
  {
    dataField: 'ruleType',
    text: 'Rule Type',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <FormatedCell
        value={value}
      />
    ),
    width: '12%',
  },
  {
    dataField: 'ruleGroup',
    text: 'Rule Group',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: any) => (
      <FormatedCell
        value={value}
      />
    ),
    width: '10%',
  },
  {
    dataField: 'productCategory',
    text: 'Category',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_, row: CheckRulesOutputType) => (
      <FormatedCell
        value={row.productCategory}
      />
    ),
    width: '10%',
  },
  {
    dataField: 'applicableProducts',
    text: 'Product',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_, row: CheckRulesOutputType) => (
      <FormatedCell
        value={formatProductNameColumn(row)}
      />
    ),
    width: '15%',
  },
  {
    dataField: 'customText',
    text: 'Custom Text',
    formatter: (_, row: CheckRulesOutputType) => (
      <FormatedCell
        value={formatCustomTextColumn(row)}
      />
    ),
    width: '10%',
  },
  {
    dataField: 'status',
    text: 'Status',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: string, _: CheckRulesOutputType) => (
      <FormatedCell
        value={value}
      />
    ),
    width: '7%',
  },
  {
    dataField: 'startDate',
    text: 'Start Date',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (_: string, row: CheckRulesOutputType) => (
      <FormatedCell
        value={dateFormatter(new Date(row.startDate))}
      />
    ),
    width: '9%',
  },
  {
    dataField: 'lastRun',
    text: 'Last Run',
    sortFunction: (a: any, b: any) => a.name.localeCompare(b.name),
    formatter: (value: string) => (
      <FormatedCell
        value={value ? dateFormatter(new Date(value)) : ''}
      />
    ),
    width: '9%',
  },
];
