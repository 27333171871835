import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast, useUserInfo } from '../../../../../hooks';
import { LIST_FINTEL_CHECK_RULE, RULE_MANAGER_DROPDOWNS_INFO } from '../graphql/queries';
import { LIST_AVAILABLE_RULE_GROUPS } from '../components/AddMonitoringRuleModal/graphql/queries';
import { DEACTIVATE_RULES_BY_IDS } from '../graphql/mutations';
import {
  MERCHANT_PREFIX, RECORDS_PER_PAGE_OPTIONS_WITH_150, TOAST_ERR_MESSAGES_NO_PAGE, path,
} from '../../../../../utils';
import {
  RULE_MANAGER, STATUS_TYPES, defaultProductCategoryOption, defaultRuleTypesOptions, defaultProductNameId, defaultRules, defaultRuleGroup, statusOptions,
} from '../enums';
import { Permission } from '../../../../../entities';
import { DELETE_RULE_BY_ID } from '../graphql/mutations/deleteRuleById';
import { CheckRulesOutputType, Rule, UseFintelRuleManagerType } from '../types';
import { ADD_RULE_GROUP } from '../graphql/mutations/addRuleGroup';
import { EDIT_RULE_BY_ID } from '../graphql/mutations/editRuleById';
import { RuleStatusSettingsType } from '../components/RuleStatusSettingsComponent/enums';
import { getLocalStorageValues, initializeLocalStorageValues, updateSavedSearchLocalStorageValues } from '../../../../../utils/persistantSearchFilters';

let fetchId = 0;

type CheckRuleGroup = {
  id: number,
  groupName: string,
}

type ProductItem = {
  label: string;
  value: string;
}

export const useFintelRuleManager = (permissionsCodeList: string[] = []): UseFintelRuleManagerType => {
  // Global Values
  const { hookShowToast } = useToast();
  const userHook = useUserInfo();
  const navigate = useNavigate();
  const location = useLocation();
  // Main page values
  const [productCategoriesOptions, setProductCategoriesOptions] = useState<SelectOption[]>([defaultProductCategoryOption]);
  const [productNameOptions, setProductNameOptions] = useState<SelectOption[]>(defaultProductNameId);
  const [ruleGroupOptions, setRuleGroupOptions] = useState<SelectOption[]>([]);

  const [ruleNameOptions, setRuleNameOptions] = useState<SelectOption[]>([defaultRules]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loadingMessage, setLoadingMessage] = useState<string>('Loading Dropdowns');

  // User options
  const [selectedProductCategory, setSelectedProductCategory] = useState<SelectOption>(defaultProductCategoryOption);
  const [selectedProductName, setSelectedProductName] = useState<SelectOption>(defaultProductNameId[0]);
  const [selectedRuleGroup, setSelectedRuleGroup] = useState<SelectOption>(defaultRuleGroup);

  const [selectedStatus, setSelectedStatus] = useState<SelectOption>(statusOptions[1]);
  const [selectedRule, setSelectedRule] = useState<SelectOption>(defaultRules);
  const [selectedRecordsAmount, setSelectedRecordsAmount] = useState<SelectOption>(RECORDS_PER_PAGE_OPTIONS_WITH_150[0]);
  const [selectedRuleType, setSelectedRuleType] = useState<SelectOption>(defaultRuleTypesOptions[0]);

  // Table data
  const [tableData, setTableData] = useState<CheckRulesOutputType[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalValues, setTotalValues] = useState(0);
  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'startDate', direction: 'desc' });
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  // Details modal
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [modalRuleId, setModalRuleId] = useState<CheckRulesOutputType | undefined>();

  // Add Rule Modal
  const [isAddRuleModalOpen, setIsAddRuleModalOpen] = useState<boolean>(false);

  // Add Rule Group Modal
  const [isRuleGroupModalOpen, setIsRuleGroupModalOpen] = useState<boolean>(false);
  const [groupName, setGroupName] = useState<string>('');
  // Add Monitoring Rule Modal
  const [isAddMonitoringRuleModalOpen, setIsAddMonitoringRuleModalOpen] = useState<boolean>(false);

  // Delete Rule
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRuleId, setDeleteRuleId] = useState<number>();

  // Rule status settings
  const [ruleStatusSettings, setRuleStatusSettings] = useState<RuleStatusSettingsType>({
    fieldsRequired: null,
    fieldState: null,
    ruleState: null,
  });

  // Queries and Mutations
  const [getRuleManager, { loading: ruleManagerLoading, error: ruleDropdownError }] = useLazyQuery(LIST_FINTEL_CHECK_RULE);
  const [getRuleManagerDropdowns, { loading: ruleDropdownLoading }] = useLazyQuery(RULE_MANAGER_DROPDOWNS_INFO);
  const [deactivateRulesByIds] = useMutation(DEACTIVATE_RULES_BY_IDS);
  const [addRuleGroup, { loading: addRuleGroupLoading }] = useMutation(ADD_RULE_GROUP);
  const [deleteRuleById] = useMutation(DELETE_RULE_BY_ID);
  const [updateCheckRule, { loading: saveRuleLoading }] = useMutation(EDIT_RULE_BY_ID);
  const [listAvailableGroupRules] = useLazyQuery(LIST_AVAILABLE_RULE_GROUPS);

  const getRulesTableDataHandler = async () => {
    setLoadingMessage('Loading Rules');
    setErrorMessage('');
    setTableLoading(true);

    // Work around for preventing old requests from being set as table data if they return after newer requests.
    // Could not get the AbortControllers to work.
    const localFetchId = fetchId + 1;
    fetchId += 1;

    enum ALL_OPTIONS {
      ALL_PRODUCTS = 'All Products',
      ALL_CATEGORIES = 'All Categories',
      ALL_RULES = 'All Rules',
      ALL_STATUS = 'All Status',
      ALL_RULE_TYPES = 'All Rule Types',
      ALL_RULE_GROUPS = 'All Rule Groups',
    }

    /* Initial input values using local state only */
    const inputValues = {
      merchantId: userHook.hookWhoAmI.companyId?.toString(),
      applicableProducts: selectedProductName.value === ALL_OPTIONS.ALL_PRODUCTS ? null : selectedProductName.value,
      productCategory: selectedProductCategory.value === ALL_OPTIONS.ALL_CATEGORIES ? null : selectedProductCategory.value,
      ruleName: selectedRule.value === ALL_OPTIONS.ALL_RULES ? null : selectedRule.value,
      status: selectedStatus.value === ALL_OPTIONS.ALL_STATUS ? null : selectedStatus.value,
      sortBy: sortColumn.column,
      ruleType: selectedRuleType.value === ALL_OPTIONS.ALL_RULE_TYPES ? null : selectedRuleType.value,
      ruleGroup: selectedRuleGroup.value === ALL_OPTIONS.ALL_RULE_GROUPS ? null : selectedRuleGroup.label,
      sortOrder: sortColumn.direction === 'asc' ? -1 : 1,
      limit: Number.parseInt(selectedRecordsAmount.value, 10),
      currentPage: page,
    };
    /* Get saved values from local storage */
    const savedValues = JSON.parse(getLocalStorageValues('ruleManagerSearch') || '{}');
    /* If there are saved values, update the input values */
    if (Object.keys(savedValues).length > 0) {
      if (savedValues.selectedProductCategory) {
        inputValues.productCategory = savedValues.selectedProductCategory.value === ALL_OPTIONS.ALL_CATEGORIES ? null : savedValues.selectedProductCategory.value;
      }
      if (savedValues.selectedProductName) {
        inputValues.applicableProducts = savedValues.selectedProductName.value === ALL_OPTIONS.ALL_PRODUCTS ? null : savedValues.selectedProductName.value;
      }
      if (savedValues.selectedRule) {
        inputValues.ruleName = savedValues.selectedRule.value === ALL_OPTIONS.ALL_RULES ? null : savedValues.selectedRule.value;
      }
      if (savedValues.selectedStatus) {
        inputValues.status = savedValues.selectedStatus.value === ALL_OPTIONS.ALL_STATUS ? null : savedValues.selectedStatus.value;
      }
      if (savedValues.selectedRuleType) {
        inputValues.ruleType = savedValues.selectedRuleType.value === ALL_OPTIONS.ALL_RULE_TYPES ? null : savedValues.selectedRuleType.value;
      }
      if (savedValues.selectedRuleGroup) {
        inputValues.ruleGroup = savedValues.selectedRuleGroup.value === ALL_OPTIONS.ALL_RULE_GROUPS ? null : savedValues.selectedRuleGroup.label;
      }
    }

    const { data, error } = await getRuleManager({
      variables: {
        input: inputValues,
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
        setTableData([]);
        setTotalPages(1);
        setTotalValues(0);
      },
    });
    if (error) {
      setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(error.message));
      setTableData([]);
      setTotalPages(1);
      setTotalValues(0);
    }

    if (data && data.fintelCheckRules && fetchId === localFetchId) {
      setTotalPages(Math.ceil(data.fintelCheckRules.count / Number.parseInt(selectedRecordsAmount.value, 10)));
      setTotalValues(data.fintelCheckRules.count);
      setTableData(data.fintelCheckRules.rules.map((rule: CheckRulesOutputType) => ({ ...rule, checked: rule.status !== STATUS_TYPES.ACTIVE })));
    }
    setLoadingMessage('');
    setTableLoading(false);
  };

  const getRuleGroupsValues = async () => {
    setErrorMessage('');
    const ruleGroupList = [defaultRuleGroup];
    const { data, error } = await listAvailableGroupRules({
      variables: {
        input: {
          merchantId: userHook?.hookWhoAmI?.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(err.message);
      },
    });
    if (error) {
      setErrorMessage(error.message);
    }
    if (data && data.listAvailableRuleGroups.checkRuleGroups) {
      setRuleGroupOptions(ruleGroupList.concat(data.listAvailableRuleGroups.checkRuleGroups.map((checkRuleGroup: CheckRuleGroup) => ({ label: checkRuleGroup.groupName, value: checkRuleGroup.groupName }))));
    }
  };

  const changePageHandler = (pageValue: number) => {
    setPage(pageValue);
  };

  function getUniqueList(data: ProductItem[]): ProductItem[] {
    try {
      const uniqueData: ProductItem[] = [];
      const labels = new Set<string>();

      data.forEach((item) => {
        if (!labels.has(item.label)) {
          uniqueData.push(item);
          labels.add(item.label);
        }
      });

      return uniqueData.length > 0 ? uniqueData : data;
    } catch (err) {
      return data;
    }
  }

  const setDropdownListsHandler = async () => {
    setErrorMessage('');
    const { data: ruleDropdownData, error } = await getRuleManagerDropdowns({
      variables: {
        input: {
          merchantId: userHook.hookWhoAmI.companyId?.toString(),
        },
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        setErrorMessage(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    let productCategoriesList = [defaultProductCategoryOption];
    let productNamesList = defaultProductNameId;
    let rulesList = [defaultRules];
    if (ruleDropdownData && ruleDropdownData.ruleManagerDropdowns && !ruleDropdownLoading && !error) {
      productCategoriesList = productCategoriesList.concat(ruleDropdownData.ruleManagerDropdowns.categories.map((cat: string) => ({ label: cat, value: cat })));
      productNamesList = productNamesList.concat(ruleDropdownData.ruleManagerDropdowns.products.map((product: string) => ({ label: product, value: product })));
      rulesList = rulesList.concat(ruleDropdownData.ruleManagerDropdowns.rules.map((rule: string) => ({ label: rule, value: rule })));
    }

    setProductCategoriesOptions(productCategoriesList);
    setProductNameOptions(getUniqueList(productNamesList));
    setRuleNameOptions(rulesList);
    getRuleGroupsValues();

    const savedValues = JSON.parse(getLocalStorageValues('ruleManagerSearch') || '{}');
    if (Object.keys(savedValues).length === 0) {
      initializeLocalStorageValues('ruleManagerSearch', {
        selectedProductCategory,
        selectedProductName,
        selectedRule,
        selectedStatus,
        selectedRuleType,
        selectedRuleGroup,
      });
    } else {
      setSelectedProductCategory(savedValues.selectedProductCategory);
      setSelectedProductName(savedValues.selectedProductName);
      setSelectedRule(savedValues.selectedRule);
      setSelectedRuleType(savedValues.selectedRuleType);
      setSelectedRuleGroup(savedValues.selectedRuleGroup);
    }
  };

  const selectProductCategoryHandler = (productCategorySelected: SelectOption) => {
    setSelectedProductCategory(productCategorySelected);
    setPage(1);
    updateSavedSearchLocalStorageValues('ruleManagerSearch', { selectedProductCategory: productCategorySelected });
  };

  const selectedRuleTypeHandler = (ruleTypeSelected: SelectOption) => {
    setSelectedRuleType(ruleTypeSelected);
    setPage(1);
    updateSavedSearchLocalStorageValues('ruleManagerSearch', { selectedRuleType: ruleTypeSelected });
  };

  const selectedRuleGroupHandler = (ruleGroupSelected: SelectOption) => {
    setSelectedRuleGroup(ruleGroupSelected);
    setPage(1);
    updateSavedSearchLocalStorageValues('ruleManagerSearch', { selectedRuleGroup: ruleGroupSelected });
  };

  const selectProductNameHandler = (productNameSelected: SelectOption) => {
    setSelectedProductName(productNameSelected);
    setPage(1);
    updateSavedSearchLocalStorageValues('ruleManagerSearch', { selectedProductName: productNameSelected });
  };

  const setDetailsModalOpenHandler = (state: boolean) => {
    setIsDetailsModalOpen(state);
  };

  const setModalRuleIdHandler = ({ rule }: Record<string, CheckRulesOutputType>) => {
    setModalRuleId(rule);
  };

  const selectRuleHandler = (ruleSelected: SelectOption) => {
    setSelectedRule(ruleSelected);
    setPage(1);
    updateSavedSearchLocalStorageValues('ruleManagerSearch', { selectedRule: ruleSelected });
  };

  const selectStatusHandler = (statusSelected: SelectOption) => {
    setSelectedStatus(statusSelected);
    setPage(1);
    updateSavedSearchLocalStorageValues('ruleManagerSearch', { selectedStatus: statusSelected });
  };

  const selectRecordsAmountHandler = (statusSelected: SelectOption) => {
    setSelectedRecordsAmount(statusSelected);
    setPage(1);
  };

  const setIsAddRuleModalOpenHandler = (state: boolean) => {
    setIsAddRuleModalOpen(state);
  };

  const setIsAddMonitoringRuleModalOpenHandler = (state: boolean) => {
    setIsAddMonitoringRuleModalOpen(state);
  };

  const resetDropdowns = () => {
    /* Reset the local storage values */
    updateSavedSearchLocalStorageValues('ruleManagerSearch', {
      selectedProductCategory: productCategoriesOptions[0],
      selectedProductName: productNameOptions[0],
      selectedRule: ruleNameOptions[0],
      selectedStatus: statusOptions[1],
      selectedRuleType: defaultRuleTypesOptions[0],
      selectedRuleGroup: defaultRuleGroup,
    });
    setSelectedProductCategory(productCategoriesOptions[0]);
    setSelectedProductName(productNameOptions[0]);
    setSelectedStatus(statusOptions[1]);
    setSelectedRule(ruleNameOptions[0]);
    setSelectedRuleGroup(defaultRuleGroup);
    setSelectedRuleType(defaultRuleTypesOptions[0]);
    setPage(1);
  };

  const setSortByHandler = (dataColumn: string, _: 'asc' | 'desc' | undefined) => {
    if (sortColumn.direction === 'desc' && sortColumn.column === dataColumn) {
      setSortColumn({ column: dataColumn, direction: 'asc' });
    } else {
      setSortColumn({ column: dataColumn, direction: 'desc' });
    }
  };

  const localTableSort = () => {
    const compare = (a: CheckRulesOutputType, b: CheckRulesOutputType) => {
      if (typeof a[sortColumn.column as keyof typeof a] === 'string') {
        const value = a[sortColumn.column as keyof typeof a] as string;
        const value2 = b[sortColumn.column as keyof typeof b] as string;
        const returnValue = (value.localeCompare(value2) * (sortColumn.direction === 'asc' ? -1 : 1));
        return returnValue;
      }
      return 0;
    };
    const newTable: CheckRulesOutputType[] = structuredClone(tableData).sort(compare);
    setTableData(newTable);
  };

  const newRuleCreated = async () => {
    setTableLoading(true);
    getRulesTableDataHandler();
    setDropdownListsHandler();
  };

  const handleDelete = (selected: Rule) => {
    setDeleteRuleId(selected.id);
    setIsDeleteModalOpen(true);
  };

  const deletePostback = async () => {
    const { errors } = await deleteRuleById({
      variables: {
        id: deleteRuleId,
      },
    });
    if (errors) {
      hookShowToast(errors[0].message);
    } else {
      setIsDeleteModalOpen(false);
      hookShowToast(RULE_MANAGER.RULE_DELETED_TOAST);

      setTableLoading(true);
      getRulesTableDataHandler();
      if (location.pathname.includes(path.fintelCheckEditRule.href)) {
        navigate(
          `${MERCHANT_PREFIX}${path.ruleManager.href}`,
        );
      }
    }
  };

  const deactivateRule = async (ruleId: number) => {
    const { errors } = await deactivateRulesByIds({
      variables: {
        ids: [ruleId],
      },
      onError(err) {
        hookShowToast(TOAST_ERR_MESSAGES_NO_PAGE(err.message));
      },
    });
    if (errors) {
      hookShowToast(errors[0].message);
    } else {
      hookShowToast(RULE_MANAGER.RULE_DEACTIVATED_TOAST);
      navigate(
        `${MERCHANT_PREFIX}${path.ruleManager.href}`,
      );
    }
  };

  /**
   * Create a new rule group with the given name
   */
  const createRuleGroup = async () => {
    const { data, errors } = await addRuleGroup({
      variables: {
        input: {
          merchantId: userHook.hookWhoAmI.companyId?.toString(),
          groupName,
        },
      },
    });
    if (errors) {
      hookShowToast(errors[0].message.includes('duplicate')
        ? RULE_MANAGER.ADD_RULE_GROUP_DUPLICATE_NAME_ERROR : RULE_MANAGER.ADD_RULE_GROUP_ERROR);
    } else if (data) {
      setIsRuleGroupModalOpen(false);
      setGroupName('');
      hookShowToast(RULE_MANAGER.ADD_RULE_GROUP_SUCCESS);
      window.location.reload();
    }
  };

  const handleEdit = (selected: Rule) => {
    navigate(
      `${MERCHANT_PREFIX}${path.fintelCheckEditRule.href}`,
      {
        state: {
          data: selected,
        },
      },
    );
  };

  const saveEditRule = async (editedRule: Rule, initialRuleState: Rule) => {
    const { data, errors } = await updateCheckRule({
      variables: {
        input: {
          id: editedRule.id,
          merchantId: userHook.hookWhoAmI.companyId?.toString(),
          ruleName: editedRule.ruleName,
          ruleGroup: editedRule.ruleGroup,
          status: editedRule.status,
          endDate: editedRule.status !== initialRuleState.status && editedRule.status === STATUS_TYPES.ACTIVE ? null : editedRule.endDate,
          ruleStatusSettings: editedRule.ruleStatusSettings,
        },
      },
    });
    if (errors) {
      hookShowToast(errors[0].message.includes('duplicate')
        ? RULE_MANAGER.ADD_RULE_GROUP_DUPLICATE_NAME_ERROR : RULE_MANAGER.EDIT_RULES.RULE_UPDATED_ERROR);
      return;
    }
    if (data) {
      if (editedRule.status !== initialRuleState.status && editedRule.status === STATUS_TYPES.INACTIVE) {
        await deactivateRule(editedRule.id);
      }
      hookShowToast(RULE_MANAGER.EDIT_RULES.RULE_UPDATED_SUCCESS);
      navigate(
        `${MERCHANT_PREFIX}${path.ruleManager.href}`,

      );
    }
  };

  // For refetching data when the paginated page changes, a dropdown values change
  useEffect(() => {
    getRulesTableDataHandler();
  }, [page, selectedProductCategory, selectedRuleType, selectedProductName, selectedRuleGroup, selectedStatus, selectedRule, selectedRecordsAmount]);

  // For refetching data or resorting data when sort column changes (only refetches if necessary)
  useEffect(() => {
    if (!tableData) return;
    if (totalValues <= Number.parseInt(selectedRecordsAmount.value, 10)) localTableSort();
    else getRulesTableDataHandler();
  }, [sortColumn]);

  // For setting the values in the dropdowns on page load
  useEffect(() => {
    setDropdownListsHandler();
  }, []);

  return {
    // Loading and Errors
    dropdownsLoading: ruleDropdownLoading,
    dropdownsError: ruleDropdownError,
    ruleManagerLoading,
    errorMessage,
    loadingMessage,

    // Rule Table Data & Handlers
    // Table Data
    tableData,
    totalPages,
    page,
    totalValues,
    sortColumn,
    tableLoading,
    // Table Handlers
    changePage: changePageHandler,
    sortByHandler: setSortByHandler,
    // Table Details Modal
    isDetailsModalOpen,
    setIsDetailsModalOpenHandler: setDetailsModalOpenHandler,
    modalRuleId,
    setModalRuleIdHandler,

    // Rule Search Options
    // Dropdown values
    selectedProductCategory,
    selectedProductName,
    selectedStatus,
    selectedRule,
    selectedRecordsAmount,
    // Dropdown change handlers
    setSelectedProductCategory: selectProductCategoryHandler,
    setSelectedProductName: selectProductNameHandler,
    setSelectedStatus: selectStatusHandler,
    setSelectedRule: selectRuleHandler,
    setSelectedRecordsAmount: selectRecordsAmountHandler,
    selectedRuleTypeHandler,
    selectedRuleGroupHandler,

    resetDropdowns,
    // Dropdown options
    productCategoriesOptions,
    productNameOptions,
    statusOptions,
    ruleNameOptions,
    recordsAmountOptions: RECORDS_PER_PAGE_OPTIONS_WITH_150,

    // Add Rule Modal
    isAddRuleModalOpen,
    setIsAddRuleModalOpen: setIsAddRuleModalOpenHandler,
    newRuleCreated,

    // Add Monitoring Rule Modal
    isAddMonitoringRuleModalOpen,
    setIsAddMonitoringRuleModalOpen: setIsAddMonitoringRuleModalOpenHandler,

    // Delete Rule Modal
    handleDelete,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    deletePostback,

    // Add Group Rule modal
    isRuleGroupModalOpen,
    setIsRuleGroupModalOpen,
    groupName,
    setGroupName,
    createRuleGroup,
    addRuleGroupLoading,

    // Edit Rule Page
    handleEdit,
    saveEditRule,
    saveRuleLoading,

    deactivateRule,
    // Rule Group, Type
    defaultRuleTypesOptions,
    selectedRuleType,
    ruleGroupOptions,
    selectedRuleGroup,

    // Rule status settings
    ruleStatusSettings,
    setRuleStatusSettings,

    isReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),

  };
};
