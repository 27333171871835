import { gql } from '@apollo/client';

export const GET_SUMMARY_DASHBOARD_DATA = gql`
  query getSummaryDashboard($input: SummaryDashboardInput!) {
    getSummaryDashboardData(input: $input) {
      checks {
        total
        urlsMonitored
        urlsIncomplete
      }
      passed {
        total
        new
        brandNew
        newlyUpdated
        closed
        internalReview
        externalReview
      }
      failed {
        total
        new
        brandNew
        newlyUpdated
        closed
        internalReview
        externalReview
      }
    }
  }
`;
