import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  AppWrapper, Button, PageTitle, Pagination, Select, Table, FixedFooter, ErrorBanner, RecordsDropdown,
} from '../../../../components';
import { path, RECORDS_PER_PAGE_OPTIONS_WITH_150 } from '../../../../utils';
import { columns } from './contracts';
import { useFintelRuleManager } from './hooks';
import {
  RuleDetailsModal, AddRuleModal, DeactivateRuleModal, AddMonitoringRuleModal,
} from './components';
import { RULE_MANAGER } from './enums';
import { DefaultPropsType } from '../../../../types';
import DeleteRuleModal from './modal/DeleteRuleModal';

import * as Styled from './styles';
import { UseFintelRuleManagerType } from './types';
import AddRuleGroupModal from './modal/AddRuleGroupModal';

type FintelCheckRuleManagerProps = DefaultPropsType;

const FintelCheckRuleManager = ({ permissionsCodeList = [] }: FintelCheckRuleManagerProps) => {
  const { isReadOnlyList, ...hook }: UseFintelRuleManagerType = useFintelRuleManager(permissionsCodeList);
  const [isReadOnly] = isReadOnlyList;

  return (
    <AppWrapper permissionsCodeList={permissionsCodeList}>
      <Styled.WrapperStyled>
        <ErrorBanner
          isOpen={!!hook.errorMessage}
          message={hook.errorMessage}
        />
        <Styled.HeaderStyled>
          <PageTitle
            isLoading={hook.dropdownsLoading || hook.ruleManagerLoading}
            loadingText={hook.loadingMessage}
          >
            {path.ruleManager.name}
          </PageTitle>

          <Styled.HeaderButtonsStyled>
            <Button
              theme="tertiary"
              onClick={() => hook.setIsRuleGroupModalOpen(true)}
              disabled={isReadOnly}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ marginRight: 8 }}
              />
              {RULE_MANAGER.ADD_RULE_GROUP_TITLE}
            </Button>

            <Button
              theme="tertiary"
              onClick={() => hook.setIsAddMonitoringRuleModalOpen(true)}
              disabled={isReadOnly}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ marginRight: 8 }}
              />
              {RULE_MANAGER.ADD_MONITORING_RULE}
            </Button>

            <Button
              theme="tertiary"
              onClick={() => hook.setIsAddRuleModalOpen(true)}
              disabled={isReadOnly}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ marginRight: 8 }}
              />
              {RULE_MANAGER.ADD_EVALUATION_RULE}
            </Button>
          </Styled.HeaderButtonsStyled>
        </Styled.HeaderStyled>

        <Styled.FiltersStyled>
          <Select
            label={RULE_MANAGER.LABELS.PRODUCT_CATEGORY}
            name="category"
            onChange={hook.setSelectedProductCategory}
            options={hook.productCategoriesOptions}
            selected={hook.selectedProductCategory}
            isLoading={hook.dropdownsLoading}
            isDisabled={hook.dropdownsLoading || !!hook.dropdownsError}
          />
          <Select
            label={RULE_MANAGER.LABELS.RULE_TYPES}
            name="ruleType"
            onChange={hook.selectedRuleTypeHandler}
            options={hook.defaultRuleTypesOptions}
            selected={hook.selectedRuleType}
            isLoading={hook.dropdownsLoading}
            isDisabled={hook.dropdownsLoading || !!hook.dropdownsError}
          />
          <Select
            label={RULE_MANAGER.LABELS.RULE_GROUPS}
            name="ruleGroup"
            onChange={hook.selectedRuleGroupHandler}
            options={hook.ruleGroupOptions}
            selected={hook.selectedRuleGroup}
            isLoading={hook.dropdownsLoading}
            isDisabled={hook.dropdownsLoading || !!hook.dropdownsError}
          />
          <Select
            label={RULE_MANAGER.LABELS.PRODUCT_NAME}
            name="product"
            onChange={hook.setSelectedProductName}
            options={hook.productNameOptions}
            selected={hook.selectedProductName}
            isLoading={hook.dropdownsLoading}
            isDisabled={hook.dropdownsLoading || !!hook.dropdownsError}
          />
          <Select
            label={RULE_MANAGER.LABELS.STATUS}
            name="status"
            onChange={hook.setSelectedStatus}
            options={hook.statusOptions}
            selected={hook.selectedStatus}
            isLoading={hook.dropdownsLoading}
            isDisabled={hook.dropdownsLoading || !!hook.dropdownsError}
          />
          <Select
            label={RULE_MANAGER.LABELS.RULES}
            name="rules"
            onChange={hook.setSelectedRule}
            options={hook.ruleNameOptions}
            selected={hook.selectedRule}
            isLoading={hook.dropdownsLoading}
            isDisabled={hook.dropdownsLoading || !!hook.dropdownsError}
          />
        </Styled.FiltersStyled>

        <Styled.RecordsStyled>
          <Styled.ExtendedRecordsStyled>
            <RecordsDropdown
              selected={hook.selectedRecordsAmount}
              options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
              currentPage={hook.page}
              totalRecords={hook.totalValues}
              onChange={hook.setSelectedRecordsAmount}
            />
          </Styled.ExtendedRecordsStyled>

          <Button
            theme="text-only"
            onClick={hook.resetDropdowns}
          >
            {RULE_MANAGER.CLEAR_FORM_BUTTON}
          </Button>
        </Styled.RecordsStyled>

        <Table
          columns={columns(isReadOnly, hook.handleDelete, hook.handleEdit)}
          data={hook.tableData}
          sortColumn={hook.sortColumn}
          onSort={hook.sortByHandler}
          setOpenModal={() => hook.setIsDetailsModalOpenHandler(true)}
          setModalInfo={hook.setModalRuleIdHandler}
          isLoading={hook.tableLoading}
        />
        {hook.tableData && (
          <Pagination
            total={hook.totalPages}
            currentPage={hook.tableData.length > 0 ? hook.page : 0}
            onPageChange={hook.changePage}
          />
        )}
      </Styled.WrapperStyled>

      <RuleDetailsModal
        value={hook.modalRuleId}
        closeModalFn={hook.setIsDetailsModalOpenHandler}
        isOpen={hook.isDetailsModalOpen}
      />
      <AddMonitoringRuleModal
        closeModalFn={hook.setIsAddMonitoringRuleModalOpen}
        isOpen={hook.isAddMonitoringRuleModalOpen}
        newRuleCreated={hook.newRuleCreated}
      />
      <AddRuleModal
        closeModalFn={hook.setIsAddRuleModalOpen}
        isOpen={hook.isAddRuleModalOpen}
        newRuleCreated={hook.newRuleCreated}
      />

      <DeleteRuleModal hook={{ ...hook, isReadOnlyList }} />

      <AddRuleGroupModal hook={{ ...hook, isReadOnlyList }} />
    </AppWrapper>
  );
};

export default FintelCheckRuleManager;
