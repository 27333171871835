import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import {
  Button, InputText, Pagination, RecordsDropdown, ReportPlaceholder, Select, Table, DragTable, PLACEHOLDER,
} from '../../../../../components';
import { RECORDS_PER_PAGE_OPTIONS_WITH_150, path, MERCHANT_PREFIX } from '../../../../../utils';
import {
  SUMMARY,
} from '../MonitoringSummaryReport/enums';
import type { useHistoryReport } from './hooks/useHistoryReport';
import { useMonitoringRulesReport } from '../../MonitoringRulesReport/hooks/useMonitoringRulesReport';

type MonitoringHistoryReportProps = {
  historyHook: ReturnType<typeof useHistoryReport>,
  hook: ReturnType<typeof useMonitoringRulesReport>,
}

export const MonitoringHistoryReport = ({ historyHook, hook }: MonitoringHistoryReportProps) => (
  <>
    <Styled.OptionsRowStyled>
      <Styled.LeftStyled>
        <RecordsDropdown
          selected={historyHook.records}
          options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
          onChange={historyHook.setRecords}
          currentPage={historyHook.currentPage}
          totalRecords={historyHook.totalRecords}
        />
      </Styled.LeftStyled>

      <Styled.RightStyled>
        <Styled.ButtonStyled
          theme="secondary"
          onClick={() => hook.setDisplaySummary(true)}
          width="160px"
        >
          Back
        </Styled.ButtonStyled>
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={() => historyHook.downloadHistoryReportCSV()}
          width="160px"
        >
          <Styled.IconStyled icon={faArrowDown} />
          {SUMMARY.DOWNLOAD_CSV}
        </Styled.ButtonStyled>
      </Styled.RightStyled>

    </Styled.OptionsRowStyled>
    {!historyHook.tableData?.length && (
      <Styled.PlaceholderBox>
        <ReportPlaceholder
          submitted={historyHook.dataGenerated}
          text={historyHook.dataGenerated ? PLACEHOLDER.TEXT_NO_RESULTS : PLACEHOLDER.TEXT_PENDING}
          subtext={historyHook.dataGenerated ? PLACEHOLDER.SUBTEXT_NO_RESULTS : PLACEHOLDER.SUBTEXT_PENDING}
        />
      </Styled.PlaceholderBox>
    )}
    {(historyHook.tableData && historyHook.tableData.length > 0) && (
    <Styled.ReportWrapper>
      <DragTable
        key={historyHook.tableKey}
        data={historyHook.tableData}
        columns={historyHook.tableColumns}
        sortColumn={historyHook.sortColumn}
        onSort={historyHook.handleSort}
        onRowClick={(row) => hook.handlerSetDetailedViewModalInfo(row)}
        onDragEnd={historyHook.setOnDragEndHandler}
      />
      <Pagination
        total={historyHook.totalPages}
        currentPage={historyHook.currentPage}
        onPageChange={historyHook.handlePageChange}
      />
    </Styled.ReportWrapper>
    )}
  </>
);
