import React from 'react';
import { faPlus, faArrowDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styles';
import {
  Button, InputText, Pagination, RecordsDropdown, ReportPlaceholder, Select, ResizeTable, ErrorBanner,
  Modal, PLACEHOLDER,
} from '../../../../../components';
import { RECORDS_PER_PAGE_OPTIONS_WITH_150 } from '../../../../../utils';
import {
  SUMMARY, RULE_STATUSES, RULE_REVIEW_STATUSES, BULK_RULE_REVIEW_STATUSES, URL_SEARCH, RULE_STATUS_LABEL, REVIEW_STATUS_LABEL,
  RULE_GROUPS_LABEL, PUBLISHERS_LABEL, SET_REVIEW_STATUS_TITLE, SET_REVIEW_STATUS_BUTTON_TITLE,
  FEEDBACK_MODAL,
} from './enums';
import type { useSummaryReport } from './hooks/useSummaryReport';
import { useMonitoringRulesReport } from '../../MonitoringRulesReport/hooks/useMonitoringRulesReport';

type MonitoringSummaryReportProps = {
  summaryHook: ReturnType<typeof useSummaryReport>,
  hook: ReturnType<typeof useMonitoringRulesReport>,
}

export const MonitoringSummaryReport = ({ hook, summaryHook }: MonitoringSummaryReportProps) => (
  <>
    <ErrorBanner
      message={summaryHook.errorMessage}
      isOpen={summaryHook.errorMessage.length > 0}
    />
    <Styled.HeaderStyled>
      <Styled.HeaderLeftStyled>
        <Styled.TitleStyled>{SUMMARY.TITLE}</Styled.TitleStyled>
      </Styled.HeaderLeftStyled>
    </Styled.HeaderStyled>
    <Styled.FilterWrapperStyled>
      <Select
        options={RULE_STATUSES}
        onChange={(e) => summaryHook.setRuleStatus(e)}
        selected={summaryHook.ruleStatus}
        label={RULE_STATUS_LABEL}
      />
      <Select
        options={RULE_REVIEW_STATUSES}
        onChange={(e) => summaryHook.setReviewStatus(e)}
        selected={summaryHook.reviewStatus}
        label={REVIEW_STATUS_LABEL}
      />
      <Select
        options={summaryHook.checkRuleOptions}
        onChange={(e) => summaryHook.setCheckRule(e)}
        selected={summaryHook.checkRule}
        label="Rules"
      />
      <Select
        options={summaryHook.ruleGroupOptions}
        onChange={(e) => summaryHook.setRuleGroup(e)}
        selected={summaryHook.ruleGroup}
        label={RULE_GROUPS_LABEL}
      />
      <Select
        options={summaryHook.publisherOptions}
        onChange={(e) => summaryHook.setPublisher(e)}
        selected={summaryHook.publisher}
        label={PUBLISHERS_LABEL}
      />
      <InputText
        type="text"
        placeholder={URL_SEARCH.TITLE}
        label={URL_SEARCH.PLACEHOLDER}
        value={summaryHook.urlSearch}
        onChange={(e) => summaryHook.setUrlSearch(e.target.value)}
      />
      <Styled.ButtonStyled
        theme="text-only"
        onClick={summaryHook.clearFormHandler}
      >
        Clear Form
      </Styled.ButtonStyled>
    </Styled.FilterWrapperStyled>
    <Styled.OptionsRowStyled>
      <Styled.LeftStyled>
        <RecordsDropdown
          selected={summaryHook.records}
          options={RECORDS_PER_PAGE_OPTIONS_WITH_150}
          onChange={summaryHook.setRecords}
          currentPage={summaryHook.currentPage}
          totalRecords={summaryHook.totalRecords}
        />
      </Styled.LeftStyled>

      <Styled.RightStyled>
        <Styled.ButtonStyled
          theme="tertiary"
          onClick={() => summaryHook.downloadMonitoringReportCSV()}
          width="160px"
          disabled={summaryHook.isLoading}
        >
          <Styled.IconStyled icon={faArrowDown} />
          {SUMMARY.DOWNLOAD_CSV}
        </Styled.ButtonStyled>
        <Button
          onClick={() => summaryHook.generateMonitoringReport()}
          width="160px"
          loading={summaryHook.isLoading}
        >
          <Styled.IconStyled icon={faPlus} />
          {SUMMARY.GENERATE_REPORT}
        </Button>
      </Styled.RightStyled>

    </Styled.OptionsRowStyled>
    {!summaryHook.tableData?.length && (
      <Styled.PlaceholderBox>
        <ReportPlaceholder
          submitted={summaryHook.dataGenerated}
          text={summaryHook.dataGenerated ? PLACEHOLDER.TEXT_NO_RESULTS : PLACEHOLDER.TEXT_PENDING}
          subtext={summaryHook.dataGenerated ? PLACEHOLDER.SUBTEXT_NO_RESULTS : PLACEHOLDER.SUBTEXT_PENDING}
        />
      </Styled.PlaceholderBox>
    )}
    {(summaryHook.tableData && summaryHook.tableData.length > 0) && (
      <Styled.ReportWrapper>
        <ResizeTable
          key={summaryHook.tableKey}
          data={summaryHook.tableData}
          columns={summaryHook.tableColumns}
          sortColumn={summaryHook.sortColumn}
          onSort={summaryHook.handleSort}
          onRowClick={(row) => hook.handlerSetDetailedViewModalInfo(row)}
          isLoading={summaryHook.isLoading}
        />
        <Pagination
          total={summaryHook.totalPages}
          currentPage={summaryHook.currentPage}
          onPageChange={summaryHook.handlePageChange}
        />

        {summaryHook.tableData && summaryHook.tableData.some((item) => item.checked) && (
          <Styled.SetStatusWrapper>
            <Styled.SubtitleStyled>
              {SET_REVIEW_STATUS_TITLE}
            </Styled.SubtitleStyled>
            <Styled.SetStatusContent>
              <Styled.SelectStyled
                options={BULK_RULE_REVIEW_STATUSES}
                onChange={(e) => hook.setSelectedReviewStatus(e)}
                selected={hook.selectedReviewStatus}
                placeholder="Select Review Status"
                menuPlacement="top"
              />
              <Button
                onClick={() => hook.selectedReviewStatus && hook.handleOnSetReviewStatus(hook.selectedReviewStatus?.value)}
                theme="primary"
                width="20rem"
                disabled={false}
                loading={hook.setReviewStatusLoading}
              >
                {SET_REVIEW_STATUS_BUTTON_TITLE}
              </Button>
            </Styled.SetStatusContent>
          </Styled.SetStatusWrapper>
        )}
      </Styled.ReportWrapper>
    )}
  </>
);
